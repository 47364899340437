import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReportsModule } from './reports/reports.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocalhostInterceptor } from './shared/interceptors/localhostInterceptor';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SideMenuComponent } from './shared/side-menu/side-menu.component';
import { MenuComponent } from './shared/menu/menu.component';
import { MenuItemComponent } from './shared/menu/menu-item.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { ButtonModule } from 'primeng/button';
import { PanelMenuModule } from 'primeng/panelmenu';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { MenubarModule } from 'primeng/menubar';
import { TabViewModule } from 'primeng/tabview';
import { pdbtoolInterceptor } from './shared/interceptors/pdbtoolInterceptor';
import { OKTA_CONFIG } from '@okta/okta-angular';
import { oktaConfig } from './shared/models/constants/oktaConfig';
import { OktaAuth } from '@okta/okta-auth-js';
import { OktaAuthModule } from '@okta/okta-angular';
import { errorlog } from '../app/shared/errorlog';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { RippleModule } from 'primeng/ripple';
import { ToastModule } from 'primeng/toast';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    MenuItemComponent,
    SideMenuComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReportsModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    SharedModule,
    ButtonModule,
    PanelMenuModule,
    InputTextModule,
    DropdownModule,
    FieldsetModule,
    TabViewModule,
    BrowserModule,

    OktaAuthModule,

    errorlog,
    MessagesModule,
    MessageModule,
    ButtonModule,
    InputTextModule,
    MenubarModule,
    RippleModule,
    ToastModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 15000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true,
      closeButton: true,
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: pdbtoolInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LocalhostInterceptor, multi: true },
    {
      provide: OKTA_CONFIG,
      useFactory: () => {
        const oktaAuth = new OktaAuth(oktaConfig);
        return { oktaAuth };
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
