import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as rxjs from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private url = '/v1/Menus';

  constructor(private http: HttpClient) {}

  getMenu() {
    return this.http.get<any[]>(this.url);
  }

  getSubMenu() {
    return this.http.get<any[]>(this.url + `/getSubMenu`);
  }
  getAllMenu() {
    return rxjs.of([{ id: 1, href: '', label: 'Home' }]);
  }
  getObjectMenu() {
    return rxjs.forkJoin([this.getMenu(), this.getSubMenu()]).pipe(
      map(([menu, submenu]: [any[], any[]]) => {
        return menu.map((x: any) => ({
          ...x,
          children: submenu.filter((s) => s.menuId == x.id),
        }));
      })
    );
  }
  getObjectMenu2() {
    return this.getAllMenu().pipe(
      map((menu: any[]) =>
        menu
          .filter((x) => !x.menuId)
          .map((x) => ({ ...x, children: this.getChildren(menu, x.id) }))
      )
    );
  }
  getChildren(menu: any[], id: number) {
    return menu
      .filter((x) => x.menuId == id)
      .map((x: any) => {
        return {
          ...x,
          children: this.getChildren(menu, x.id),
        };
      });
  }
}
