import { EventEmitter, NgModule, Output } from '@angular/core';
import {
  ProgressStatus,
  ProgressStatusEnum,
} from 'src/app/shared/models/progress-status.model';
@NgModule({
  declarations: [],
})
export class errorlog {
  @Output() showMessage = new EventEmitter<boolean>();
  @Output() showProgress = new EventEmitter<boolean>();

  showDownloadError: boolean;
  showSorceError: boolean;
  showproductError: boolean;
  public sucessflag: boolean;
  percentage: number;
  status: boolean;
  
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  public downloadProgress(event: ProgressStatus) {
    switch (event.status) {
      case ProgressStatusEnum.IN_PROGRESS:
        this.status = true;
        this.showProgress.emit(this.status);
        this.showMessage.emit(this.status);
        this.percentage = event.percentage;
        return true;
        break;
    }
  }

  public downloadStatus(event: ProgressStatus) {
    switch (event.status) {
      case ProgressStatusEnum.START:
        this.showDownloadError = false;
        this.showSorceError = false;
        this.showproductError = false;
        this.status = true;
        return this.status;
        break;
      case ProgressStatusEnum.IN_PROGRESS:
        this.status = true;
        this.showProgress.emit(this.status);
        this.showMessage.emit(this.status);
        this.percentage = event.percentage;
        return this.status;
        break;
      case ProgressStatusEnum.COMPLETE:
        this.showProgress.emit(false);
        this.showMessage.emit(false);
        this.showMessage.emit(false);
        break;
      case ProgressStatusEnum.ERROR:
        this.showProgress.emit(false);
        this.showMessage.emit(false);
        this.showSorceError = false;
        this.showproductError = false;
        this.showDownloadError = true;
        this.status = false;
        return this.status;
        break;
    }
  }
}
