import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { scheduleInfo } from '../models/scheduleInfo.model';

@Injectable({
  providedIn: 'root',
})
export class ScheduledDatesService {
  private url = '/v1/ScheduledDates';

  constructor(private http: HttpClient) {}

  public async getNextScheduledDates(cronExpression: string, startDate: Date) {
    let params = {
      cronExpression,
      startDate: startDate.toISOString(),
    };

    return await this.http.get<Date[]>(this.url, { params }).toPromise();
  }

  public saveSheduleInfo(data: scheduleInfo) {
    console.log(data, 'inservice');
    return this.http.post<any>(this.url + '/sheduleInfo', data);
  }

  public UpdateSheduleInfo(data: scheduleInfo) {
    console.log(data, 'inservice');
    return this.http.post<any>(this.url + '/sheduleInfoEdit', data);
  }

  public getSheduleInfo(menuId: string) {
    return this.http.get<scheduleInfo[]>(
      this.url + `/${menuId}/GetShedulerInfo`
    );
  }

  public DownloadReportAWSS3({
    report,
  }: {
    report: string;
  }): Observable<HttpEvent<Blob>> {
    console.log(report, 'In-service');
    return this.http.request(
      new HttpRequest('GET', `${this.url}/Download/${report}`, null, {
        reportProgress: true,
        responseType: 'blob',
      })
    );
  }
}
