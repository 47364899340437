<ng-container>
    <div class="grid mb-2">
        <div class="font16 col-1" style="padding-top: 10px; font-weight:500">Scheduler</div>
        <div class="col">
            <p-inputSwitch [(ngModel)]="sheduleSwitchchecked"></p-inputSwitch>
        </div>
    </div>
    <p *ngIf="[showerror]"> <strong>{{errorMsg}}</strong> </p>
    <div class="grid">
        <div class="col font16 font-medium">Recurence Pattern</div>
    </div>
    <div class="grid">
        <div class="col-4">
            <div class="p-text-medium mb-1">Frequency:</div>
            <div class="grid">
                <div *ngFor="let category of categories" class="col">
                    <p-radioButton [inputId]="category.key" name="category" [value]="category"
                        [(ngModel)]="selectedFrequency" (onClick)="changeFrequency($event)"
                        [disabled]="category.key === 'R'"></p-radioButton>
                    <label [for]="category.key">{{category.name}}</label>
                </div>
            </div>
        </div>
        <div class="col-2">
            <div class="p-text-medium mb-1">Start Time (UTC)</div>
            <p-calendar [(ngModel)]="date" [timeOnly]="true" [hourFormat]="24" inputId="timeonly"
                [required]="true"></p-calendar>
        </div>
        <div class="col-6">
            <div class="p-text-medium mb-1">Frequency Details</div>
            <div id="fdDaily" *ngIf="selectedFrequency.key == 'D'">
                <div class="flex align-items-stretch flex-wrap mb-2">
                    <div class="flex align-items-center justify-content-center mr-1">
                        <p-radioButton name="fdDaily" value="Daily" [(ngModel)]="selectedValue"
                            label="Every"></p-radioButton>
                    </div>
                    <div class="align-self-center flex align-items-center justify-content-center mr-1">
                        <p-inputNumber [(ngModel)]="valDaily" [showButtons]="true" [min]="1" [max]="30"></p-inputNumber>
                    </div>
                    <div class="flex align-items-center justify-content-center">day</div>
                </div>
                <div class="flex align-items-stretch flex-wrap">
                    <div class="flex align-items-center justify-content-center mr-1">
                        <p-radioButton name="fdDaily" value="WeekDays" [(ngModel)]="selectedValue"
                            label="Every Weekday"> </p-radioButton>
                    </div>
                </div>
            </div>
            <div class="Weekly" id="fdWeekly" *ngIf="selectedFrequency.key == 'W'">
                <div class="flex align-items-stretch flex-wrap">
                    <div class="flex align-items-center justify-content-center mr-1">
                        Recur every
                    </div>
                    <div class="flex align-items-center justify-content-center mr-1">
                        <p-inputNumber [(ngModel)]="valWeekly" [showButtons]="true" [min]="1" [max]="5"></p-inputNumber>
                    </div>
                    <div class="flex align-items-center justify-content-center mr-1">
                        week on
                    </div>
                </div>
                <div class="grid mt-2">
                    <div class="col">
                        <p-checkbox type="checkbox" name="fdWeeklychk" value="Monday" label="Monday"
                            [(ngModel)]="selectedCheckBox"></p-checkbox>
                    </div>
                    <div class="col">
                        <p-checkbox type="checkbox" name="fdWeeklychk" value="Tuesday" label="Tuesday"
                            [(ngModel)]="selectedCheckBox"></p-checkbox>
                    </div>
                    <div class="col">
                        <p-checkbox type="checkbox" name="fdWeeklychk" value="Wedsday" label="Wednesday"
                            [(ngModel)]="selectedCheckBox"></p-checkbox>
                    </div>
                    <div class="col">
                        <p-checkbox type="checkbox" name="fdWeeklychk" value="Thursday" label="Thursday"
                            [(ngModel)]="selectedCheckBox"></p-checkbox>
                    </div>
                    <div class="col">
                        <p-checkbox type="checkbox" name="fdWeeklychk" value="Friday" label="Friday"
                            [(ngModel)]="selectedCheckBox"></p-checkbox>
                    </div>
                    <div class="col">
                        <p-checkbox type="checkbox" name="fdWeeklychk" value="Saturday" label="Saturday"
                            [(ngModel)]="selectedCheckBox"></p-checkbox>
                    </div>
                    <div class="col">
                        <p-checkbox type="checkbox" name="fdWeeklychk" value="Sunday" label="Sunday"
                            [(ngModel)]="selectedCheckBox"></p-checkbox>
                    </div>
                </div>
            </div>
            <div class="Monthly" id="fdMonthly" *ngIf="selectedFrequency.key == 'M'">
                <div class="flex align-items-stretch flex-wrap mb-3">
                    <div class="flex align-items-center justify-content-center mr-1">
                        <p-radioButton name="groupname" value="NoDayMonthR" [(ngModel)]="RadioselectedValueMonthly"
                            label="Day"></p-radioButton>
                    </div>
                    <div class="flex align-items-center justify-content-center mr-1">
                        <p-inputNumber [(ngModel)]="valMonthday" [showButtons]="true" [min]="1"
                            [max]="30"></p-inputNumber>
                    </div>
                    <div class="flex align-items-center justify-content-center mr-1">
                        of every
                    </div>
                    <div class="flex align-items-center justify-content-center mr-1">
                        <p-inputNumber [(ngModel)]="valMonth" [showButtons]="true" [min]="1" [max]="12"></p-inputNumber>
                    </div>
                    <div class="flex align-items-center justify-content-center mr-1">
                        month
                    </div>
                </div>
                <div class="flex align-items-stretch flex-wrap">
                    <div class="flex align-items-center justify-content-center mr-1">
                        <p-radioButton name="groupname" value="DayMonthR" [(ngModel)]="RadioselectedValueMonthly"
                            label="The"></p-radioButton>
                    </div>
                    <div class="flex align-items-center justify-content-center mr-1">
                        <p-dropdown [options]="Accuryencies" [(ngModel)]="selectedAccurance"
                            optionLabel="name"></p-dropdown>
                    </div>
                    <div class="flex align-items-center justify-content-center mr-1">
                        <p-dropdown [options]="Weekdaysone" [(ngModel)]="selectedWeekdays"
                            optionLabel="name"></p-dropdown>
                    </div>
                    <div class="flex align-items-center justify-content-center mr-1">
                        of every
                    </div>
                    <div class="flex align-items-center justify-content-center mr-1">
                        <p-inputNumber [(ngModel)]="val" [showButtons]="true" [min]="0" [max]="12"></p-inputNumber>
                    </div>
                    <div class="flex align-items-center justify-content-center mr-1">
                        month
                    </div>
                </div>
            </div>
            <div class="Yearly" id="fdYearly" *ngIf="selectedFrequency.key == 'Y'">
                <div class="flex align-items-stretch flex-wrap mb-2">
                    <div class="flex align-items-center justify-content-center mr-1">
                        Recur Every
                    </div>
                    <div class="flex align-items-center justify-content-center mr-1">
                        <p-inputNumber [(ngModel)]="val" [showButtons]="true" [min]="0" [max]="100"></p-inputNumber>
                    </div>
                    <div class="flex align-items-center justify-content-center mr-1">
                        year
                    </div>
                </div>
                <div class="flex align-items-stretch flex-wrap mb-2">
                    <div class="flex align-items-center justify-content-center mr-1">
                        <p-radioButton name="groupYearly" value="val01" [(ngModel)]="selectedValue"
                            label="On"></p-radioButton>
                    </div>
                    <div class="flex align-items-center justify-content-center mr-1">
                        <p-dropdown [options]="Accuryencies" [(ngModel)]="selectedAccurance"
                            optionLabel="name"></p-dropdown>
                    </div>
                    <div class="flex align-items-center justify-content-center mr-1">
                        <p-dropdown [options]="Weekdaysone" [(ngModel)]="selectedWeekdays"
                            optionLabel="name"></p-dropdown>
                    </div>
                    <div class="flex align-items-center justify-content-center mr-1">
                        <p-dropdown [options]="Weekdaysone" [(ngModel)]="selectedWeekdays"
                            optionLabel="name"></p-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="RangeofRecurrence">
        <div class="grid">
            <div class="col font16 font-medium">Range of Recurence</div>
        </div>
        <div class="grid mb-1">
            <div class="col-12 p-text-medium">Start</div>
        </div>
        <div class="grid">
            <div class="col-4">
                <p-calendar [(ngModel)]="startDate" [showIcon]="true" inputId="icon" [firstDayOfWeek]="1"
                    [required]="true" dateFormat="dd-MM-yy"></p-calendar>
            </div>
            <div class="col-6">
                <div class="flex align-items-stretch flex-wrap mb-2">
                    <div class="flex align-items-center justify-content-center mr-1">
                        <p-radioButton name="groupRecur" value="valEndDate" [(ngModel)]="RadioselectRecurence"
                            label="End By"></p-radioButton>
                    </div>
                    <div class="flex align-items-center justify-content-center mr-1">
                        <p-calendar [(ngModel)]="endDate" [showIcon]="true" [firstDayOfWeek]="1" inputId="icon"
                            dateFormat="dd-MM-yy"></p-calendar>
                    </div>
                </div>
                <div class="flex align-items-stretch flex-wrap mb-2">
                    <div class="flex align-items-center justify-content-center mr-1">
                        <p-radioButton name="groupRecur" value="valrecurButton" [(ngModel)]="RadioselectRecurence"
                            label="End after"></p-radioButton>
                    </div>
                    <div class="flex align-items-center justify-content-center mr-1">
                        <p-inputNumber [(ngModel)]="valEndAfterRecc" [showButtons]="true" [min]="0"
                            [max]="100"></p-inputNumber>
                    </div>
                    <div class="flex align-items-center justify-content-center mr-1">
                        occurances
                    </div>
                </div>
                <div class="flex align-items-stretch flex-wrap">
                    <p-radioButton name="groupRecur" value="valNoEndDate" [(ngModel)]="RadioselectRecurence"
                        label="No end date"></p-radioButton>
                </div>
            </div>
        </div>
    </div>
    <div class="grid">
        <div class="col-12 p-text-right">
            <button class="p-button-rounded p-button-warning mr-1" pButton (click)="saveSchedule()"
                *ngIf="!btnType">Save Scheduler</button>
            <button class="p-button-rounded p-button-warning" pButton (click)="saveSchedule()" *ngIf="btnType">Update
                Scheduler</button>
        </div>
    </div>
    <p-toast></p-toast>
</ng-container>