import { formatDate } from '@angular/common';
import { Component, OnInit, Output } from '@angular/core';
import { ProgressStatusEnum } from 'src/app/shared/models/progress-status.model';
import { RunReport } from 'src/app/shared/models/runreport.model';
import { ProductService } from 'src/app/shared/services/ProductService';
import { ReportModel } from '../../models/report.class';
import { errorlog } from '../../../shared/errorlog';
import { HttpEventType } from '@angular/common/http';
import { SignalrService } from 'src/app/shared/services/signalr.service';
import * as filesaver from 'file-saver';
import { MessageService } from 'primeng/api';
import { messagesConst } from 'src/app/shared/models/constants/messagesConst';
import { zipFoldeNameservice } from "../../../shared/services/zipFoldeName.service";


@Component({
  selector: 'app-custom-sfdrbmr-soverign',
  templateUrl: './custom-sfdrbmr-soverign.component.html',
  styleUrls: ['./custom-sfdrbmr-soverign.component.scss'],
})
export class CustomSfdrbmrSoverignComponent implements OnInit {
  date14: Date;
  selectedSource: string;
  runReportObj = new RunReport();
  @Output() selectedPrdct: string;
  @Output() menuIds: string;

  sources: any[] = [{ name: 'Test', key: 'Test' }, { name: 'Live', key: 'Live' }];
  public reports: ReportModel[] = [];
  public selectedReport: ReportModel = new ReportModel();

  selectedProduct: string;
  public disabled: boolean;
  public fileName: string;
  public fileInDownload: string;
  public percentage: number;
  public showProgress: boolean;
  public showDownloadError: boolean;
  public showMessage: boolean;
  public showMessage1: boolean;
  public loading: boolean;
  public menuId: any;
  public tabselected: string;
  public uploadstatus: string;
  activeState: boolean[] = [true, false, false];
  targetedFiled: any;
  targetedUniverse: any;
  date1: string;
  menuName: any;
  dateTime = new Date();
  SpinnerPNG: boolean;

  constructor(
    private pdbService: ProductService,
    public statusLoggin: errorlog,
    public singlarService: SignalrService,
    private messageService: MessageService,
    public zipFoldeName: zipFoldeNameservice,


  ) {
    this.menuId = 16;
    this.menuName = history.state.name;
    this.singlarService.startConnection();
    this.singlarService.addProductListnerCustom();
  }
  ngOnInit(): void {
    // this.menuIds = this.menuName;
    this.menuIds = 'SFDR-BMR Sovereign';
  }
  public run() {
    if (this.tabselected == null || this.tabselected == 'Universe') {
      this.showMessage1 = false;
      this.tabselected = 'Universe';
    } else {
      if (
        this.uploadstatus == 'Fail' ||
        this.uploadstatus == null ||
        (this.tabselected == 'Upload' && this.uploadstatus == 'inUniverse')
      ) {
        this.showMessage1 = true;
        return;
      } else {
        this.showMessage1 = false;
        this.tabselected = 'Upload';
      }
    }
    if (this.date14 == null || this.date14 == undefined) {
      this.date14 = new Date();
      console.log(this.date14, 'date is');
    }


    if (!this.selectedSource) {
      this.messageService.add({
        severity: 'warn',
        summary: 'warning',
        detail: messagesConst.failMsgSource,
      });
      return;
    }
    if (!this.targetedFiled) {

      this.messageService.add({
        severity: 'warn',
        summary: 'warning',
        detail: messagesConst.failMsgtargetedFiled,
      });
      return;
    }

    if (
      (!this.targetedUniverse) &&
      (!this.uploadstatus || this.uploadstatus == 'Fail')
    ) {

      this.messageService.add({
        severity: 'warn',
        summary: 'warning',
        detail: messagesConst.failMsgtargetedUniverse,
      });
      return;
    }

    this.selectedProduct = 'Soverign';
    this.date1 = formatDate(this.date14, 'yyyy-MM-dd', 'en_US');
    this.runReportObj.id = this.menuId;
    this.runReportObj.date = this.date1;
    this.runReportObj.source = this.selectedSource;
    this.runReportObj.subProduct = this.selectedProduct;
    this.runReportObj.targetedField = this.targetedFiled;
    this.runReportObj.universe = this.targetedUniverse;
    this.runReportObj.selectedcomponyType = this.tabselected;

    this.statusLoggin.downloadStatus({ status: ProgressStatusEnum.START });
    this.showMessage = this.statusLoggin.downloadStatus({
      status: ProgressStatusEnum.START,
    });
    this.pdbService.runNewReport(this.runReportObj).subscribe(
      (res) => {
        switch (res.type) {
          case HttpEventType.DownloadProgress:
            this.statusLoggin.downloadStatus({
              status: ProgressStatusEnum.IN_PROGRESS,
              percentage: Math.round((res.loaded / res.total) * 100),
            });
            this.showProgress = this.statusLoggin.downloadStatus({
              status: ProgressStatusEnum.IN_PROGRESS,
              percentage: Math.round((res.loaded / res.total) * 100),
            });

            break;

          case HttpEventType.Response:
            this.statusLoggin.downloadStatus({
              status: ProgressStatusEnum.COMPLETE,
            });
            const downloadedFile = new Blob([res.body], {
              type: res.body.type,
            });

            filesaver.saveAs(downloadedFile, this.zipFoldeName.getZipfolderName('SFDR-BMR Sovereign', this.selectedSource));

            break;
        }
        this.SpinnerPNG = false;
        this.showMessage = this.statusLoggin.downloadStatus({
          status: ProgressStatusEnum.COMPLETE,
        });
        this.showProgress = this.statusLoggin.downloadStatus({
          status: ProgressStatusEnum.COMPLETE,
        });
      },
      (error) => {
        this.SpinnerPNG = false;
        this.showMessage = false;
        this.showProgress = false;
        this.statusLoggin.downloadStatus({ status: ProgressStatusEnum.ERROR });
      }
    );

    this.SpinnerPNG = false;
    this.statusLoggin.downloadStatus({ status: ProgressStatusEnum.COMPLETE });

  }

  changeEnvirement(e) {
    // is called from inside component. for this specific component it doesn't do anything yet.
  }

  nullSource() {
    this.selectedSource = null;
  }
  public getTagetedfile(targetedFileFromChild: any): void {
    this.targetedFiled = targetedFileFromChild;
  }
  public getTagetedUniverse(targetdeUnivereseChild: []) {
    this.targetedUniverse = targetdeUnivereseChild;
  }

  public getUploadStatus(status: string) {
    this.uploadstatus = status;
  }

  handleChange(event) {
    var index = event.index;
    if (index == 0) {
      this.tabselected = 'Universe';
      this.uploadstatus = 'inUniverse';
    } else {
      this.tabselected = 'Upload';
    }
  }


}
