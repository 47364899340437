<div class="grid homeTiles shadow-4 p-2 mt-1">
  <div class="col-2">
    <div class="p-text-medium mb-1">Source</div>
    <p-radioButton [(ngModel)]="selectedSource" label="{{source.name}}" [value]="source.key"
      *ngFor="let source of sources" class="inline mr-2" (onClick)="changeEnvirement()"></p-radioButton>


  </div>
  <div class="col-4">
    <div class="p-text-medium mb-1">Select Date</div>
    <p-calendar [(ngModel)]="date14" [showIcon]="true" [inline]="false" [showWeek]="false" [firstDayOfWeek]=" 1"
      [maxDate]="dateTime"></p-calendar>
  </div>
  <div class="col-6">
  </div>
  <div class="col-12 mt-3">
    <div class="p-text-medium mb-1">Product Structure Data Fields</div>
    <app-data-field (targetedFiled)="getTagetedfile($event)" [menuIds]="menuIds"
      [envirement]="selectedSource"></app-data-field>
  </div>
  <div class="col-12 mt-3">
    <div class="p-text-medium mb-1">Companies included in reports</div>
    <p-tabView (onChange)="handleChange($event)">
      <p-tabPanel header="Select Universe">
        <app-universe-filed (targetedUniverse)="getTagetedUniverse($event)"></app-universe-filed>
      </p-tabPanel>
      <p-tabPanel header="Upload Company IDs">
        <app-upload-company-id (uploadStatus)="getUploadStatus($event)"></app-upload-company-id>
      </p-tabPanel>
    </p-tabView>
  </div>
  <div class="col-12 mt-3 text-right ">
    <button class="p-button-rounded p-button-warning" pButton (click)="run()" [disabled]="showProgress || showMessage"
      (downloadStatus)="statusLoggin.downloadStatus($event)">Download QA
      Report</button>
  </div>
</div>

<div class="">
  <p *ngIf="showMessage">The output is being processed, please wait <img src="assets/images/spinning-circles.svg" />
  </p>
  <p *ngIf="showProgress">Download progress is <strong>{{percentage}}%</strong> <img
      src="assets/images/spinning-circles.svg" /> </p>
  <label class="error" [hidden]="!showDownloadError">Error ocurred either during execution of stored procedure
    or during download </label>

</div>

<div id="reportList">

  <div *ngFor="let report of reports" style="visibility: hidden;">
    <input pInputText class="p-inputtext-lg" id="report.storedProcedureName" [value]='report' type="radio"
      name="reports" [(ngModel)]="selectedReport" (change)="handleChange(selectedReport)">
    {{ report.storedProcedureName }}
  </div>
</div>

<p-toast></p-toast>