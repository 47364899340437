import { HttpEventType } from '@angular/common/http';
import { Component, OnInit, Input, NgModule } from '@angular/core';
import { PDBToolboxService } from '../../../shared/services/PDBToolbox.service';
import { ReportModel } from '../../models/report.class';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  public reports: ReportModel[] = [];
  public selectedReport: ReportModel = new ReportModel();

  public disabled: boolean;
  public fileName: string;
  public fileInDownload: string;
  public percentage: number;
  public showProgress: boolean;
  public showDownloadError: boolean;
  public showMessage: boolean;
  public loading: boolean;

  constructor(private pdbService: PDBToolboxService) {}

  ngOnInit(): void {
    // can't be removed due to OnInit implementation
  }
}
