import { Component, Output } from '@angular/core';
import { errorlog } from 'src/app/shared/errorlog';
import { PDBToolboxService } from 'src/app/shared/services/PDBToolbox.service';
import { zipFoldeNameservice } from 'src/app/shared/services/zipFoldeName.service';
import { ReportModel } from '../../models/report.class';
import { ProgressStatusEnum } from 'src/app/shared/models/progress-status.model';
import { HttpEventType } from '@angular/common/http';
import * as filesaver from 'file-saver';

interface Envirements {
  name: string;
  code: string;
}

@Component({
  selector: 'app-muni-report',
  templateUrl: './muni-report.component.html',
  styleUrls: ['./muni-report.component.scss']
})
export class MuniReportComponent {
  selectedSource: string;
  sources: any[] = [{ name: 'Test', key: 'Test' }, { name: 'Live', key: 'Live' }];
  @Output() menuIds: string;
  selectedPrdct: string;
  public reports: ReportModel[] = [];
  public selectedReport: ReportModel = new ReportModel();
  AllEnvirements: Envirements[];

  selectedEnvirements: Envirements;
  //
  public disabled: boolean;
  public fileName: string;
  public fileInDownload: string;
  public percentage: number;
  public showProgress: boolean;
  public showDownloadError: boolean = false;
  public showMessage: boolean;
  public loading: boolean;
  public menuId: any;
  public env: any;
  showproductError: boolean;
  showSorceError: boolean;
  today = new Date();
  constructor(
    private pdbService: PDBToolboxService,
    public statusLoggin: errorlog,
    public zipFoldeName: zipFoldeNameservice

  ) {
    this.menuId = 20;
  }


  ngOnInit(): void {
    this.AllEnvirements = [
      { name: 'MUNI CSV Upload ', code: 'CU' },
      { name: 'Overall Company', code: 'OCR' },
      { name: 'Esg Projection Metric capital Part1', code: 'EPMCR' },
      { name: 'Esg Projection Metric capital Part2', code: 'EPMCR2' },
      { name: 'Esg Projection Metric', code: 'EPMR' },
      { name: 'Wealth Metric Capital', code: 'WMCR' },
      { name: 'Metric Wealth Adjustment', code: 'MWAR' },
      { name: 'Capital Esg Performance', code: 'CEPR' },
      { name: 'Capital Risk Rating', code: 'CRRR' },
      { name: 'Capital Wealth and Stranded Assets', code: 'CWSAR' },


    ];
    // can't be removed due to OnInit implementation
    this.menuIds = this.menuId
  }

  public AuditInfo() {
    this.selectedEnvirements;
    this.selectedSource;
    console.log(this.selectedEnvirements);
    console.log(this.selectedSource);

    this.loading = false;
    console.log(this.menuId);
    console.log(this.menuId, 'menuId');


    if (this.selectedEnvirements) {

      this.selectedPrdct = this.selectedEnvirements['code'];

    }
    else {

      this.showDownloadError = false;
      this.showSorceError = false;
      this.showproductError = true;
      return;
    }
    this.pdbService
      .getPulishedDateWithSubPrduct(this.menuId, this.selectedSource, this.selectedPrdct)
      .subscribe((result) => {
        this.reports = result;
        console.log(this.reports);
        this.selectedReport = this.reports[0];
        this.pdbService
          .getAuditInfo(this.selectedReport.auditProcedureName, this.menuId)
          .subscribe(
            (auditResult) => (this.selectedReport.auditInfo = auditResult)
          );
      });
  }

  public run() {

    if (this.selectedReport.storedProcedureName != undefined) {
      this.showSorceError = false;
      this.statusLoggin.downloadStatus({ status: ProgressStatusEnum.START });
      this.showMessage = this.statusLoggin.downloadStatus({
        status: ProgressStatusEnum.START,
      });

      this.pdbService
        .runReport({
          report: this.selectedReport.storedProcedureName,
          id: this.menuId,
        })
        .subscribe(
          (data) => {
            switch (data.type) {
              case HttpEventType.DownloadProgress:
                this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.IN_PROGRESS,
                  percentage: Math.round((data.loaded / data.total) * 100),
                });
                this.showProgress = this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.IN_PROGRESS,
                  percentage: Math.round((data.loaded / data.total) * 100),
                });
                break;
              case HttpEventType.Response:
                this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.COMPLETE,
                });
                const downloadedFile = new Blob([data.body], {
                  type: data.body.type,
                });

                this.showProgress = this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.COMPLETE,
                });
                this.showMessage = this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.COMPLETE,
                });

                filesaver.saveAs(downloadedFile, this.zipFoldeName.getZipfolderNameCarbon('Muni', this.selectedEnvirements['name'], this.selectedSource))

                break;
            }

          },
          (error) => {
            this.showDownloadError = true;
            this.statusLoggin.downloadStatus({
              status: ProgressStatusEnum.ERROR


            }

            );
          }
        );
    } else {

      this.showDownloadError = false;
      this.showproductError = false;
      this.showSorceError = true;
    }
  }

  handleChange() {
    if (this.selectedReport.auditInfo === undefined) {
      this.loading = true;
      this.pdbService
        .getAuditInfo(this.selectedReport.auditProcedureName, this.menuId)
        .subscribe((auditResult) => {
          this.selectedReport.auditInfo = auditResult;
          this.loading = false;
        });
    }
  }
  changeEnvirement(e) {
    this.AuditInfo();
  }
  nullSource() {
    this.selectedSource = null;
    this.showproductError = false;
  }

}
