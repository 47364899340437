import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommonmsgService {
  public getcommanMsg(successmgs: boolean) {
    return successmgs;
  }
}
