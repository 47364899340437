import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './angular-material.module';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';
import { FieldsetModule } from 'primeng/fieldset';
import { InputSwitchModule } from 'primeng/inputswitch';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MaterialModule,
    SidebarModule,
    MenuModule,
    FieldsetModule,
    InputSwitchModule,
  ],

  exports: [MaterialModule, MenuModule, SidebarModule],
})
export class SharedModule {}
