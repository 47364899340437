<div class="grid homeTiles shadow-4 p-2 mt-1">
  <div class="col-2">
    <div class="p-text-medium mb-1">Product</div>
    <p-dropdown id="dropdownEnv" [options]="AllEnvirements" optionLabel="name" placeholder="Select Product"
      class="form-control" [(ngModel)]="selectedEnvirements" (onChange)="nullSource()">

    </p-dropdown>


    <div class="p-text-medium mb-1 mt-3">Source</div>
    <p-radioButton [(ngModel)]="selectedSource" label="{{source.name}}" [value]="source.key"
      *ngFor="let source of sources" class="inline mr-2" (onClick)="changeEnvirement()"></p-radioButton>


  </div>
  <div class="col-2">
    <div class="p-text-medium mb-1">Data Source</div>
    {{selectedSource}}
  </div>
  <div class="col-2">
    <div class="p-text-medium mb-1">Latest Import Date</div>
    {{ selectedReport.auditInfo?.lastUploadDate | date: 'EEEE dd/MM/yyyy' }}
  </div>
  <div class="col-2">
    <div class="p-text-medium mb-1">Last Publication Date</div>
    {{ selectedReport.auditInfo?.lastPublishDate | date: 'EEEE dd/MM/yyyy' }}
  </div>
  <div class="col-4">
    <button pButton (click)="run()" class="p-button-rounded p-button-warning" [disabled]="showProgress || showMessage"
      (downloadStatus)="this.statusLoggin.downloadStatus($event)">Download QA Report</button>
  </div>
  <div class="col-12">
    <ng-container>
      <p-fieldset legend="" [toggleable]=" true" id="ScheduleParameter" class="fieldset-spacing">

        <app-sheduler-picker [menuIds]="menuIds"></app-sheduler-picker>


      </p-fieldset>
    </ng-container>
  </div>
  <p *ngIf="showMessage">The output is being processed, please wait <img src="assets/images/spinning-circles.svg" />
  </p>
  <p *ngIf="showProgress">Download progress is <strong>{{percentage}}%</strong> <img
      src="assets/images/spinning-circles.svg" /> </p>
  <label class="error" [hidden]="!showDownloadError">Error ocurred either during execution of stored procedure
    or during download </label>

  <label class="error" [hidden]="!showSorceError"> Please select Product or Source before download. </label>

  <label class="error" [hidden]="!showproductError"> Please select Product </label>


  <div id="reportList">
    <div *ngFor="let report of reports" style="visibility: hidden;">
      <input pInputText class="p-inputtext-lg" id="report.storedProcedureName" [value]='report' type="radio"
        name="reports" [(ngModel)]="selectedReport" (change)="handleChange(selectedReport)">
      {{ report.storedProcedureName }}
    </div>
  </div>