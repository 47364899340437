import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuComponent } from './shared/menu/menu.component';

const routes: Routes = [{ path: 'menuData/:ID', component: MenuComponent }];

// @NgModule({
//   // // imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
//   // exports: [RouterModule]
// })

@NgModule({

  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]

})
export class AppRoutingModule { }
