import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { Subscription } from 'rxjs';
import { TabMenuType, RouterTabMenuModel } from './router-tab-menu.component.model';

@Component({
    selector: 'app-router-tab-menu',
    templateUrl: './router-tab-menu.component.html',
    styleUrls: ['./router-tab-menu.component.scss']
})
export class RouterTabMenuComponent implements OnInit, OnDestroy {

    @Input() menuItems: RouterTabMenuModel[] = [];
    @Input() mode: TabMenuType = TabMenuType.vertical;
    
    @Input("iconTemplate") iconTemplateRef?: TemplateRef<any>;

    public current = '';
    private routeSubscription: Subscription;

    constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

    ngOnInit(): void {
        this.processCurrentRoute();
        this.routeSubscription = this.router.events.subscribe(newUrl => {
            if (newUrl instanceof NavigationEnd) {
                this.processCurrentRoute();
            }
        });
    }

    ngOnDestroy(): void{
        this.routeSubscription?.unsubscribe();
    }

    public changeTab(tab: RouterTabMenuModel): void {
        this.current = tab.value;
        this.router.navigate([tab.value], { relativeTo: this.activatedRoute });
    }

    private processCurrentRoute(): void {
        const tree = this.router.parseUrl(this.router.url);
        const primary: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        const primarySegments: UrlSegment[] = primary.segments;
        this.current = primarySegments[primarySegments.length - 1].path;
    }

}
export { RouterTabMenuModel };

