import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { MenuService } from '../services/menu.service';
import { OKTA_CONFIG, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { filter } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { Message, MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { CommonmsgService } from '../services/commonmsg.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [MessageService, CommonmsgService],
})
export class HeaderComponent implements OnInit {
  @Input() msgs1: Message[];
  @Input() sucessflag: boolean;
  loggedIn = false;
  menuCollapsed = true;
  display: boolean = false;
  @Input() userFullName: string;
  public userInitials: string;
  userName: string;
  lastName: string;
  public message = '';
  public name = '';

  public FirstName: string;
  @ViewChild(MatSidenav) sideNav!: MatSidenav;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuService: MenuService,
    public router: Router,
    public msgComm: CommonmsgService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    authStateService: OktaAuthStateService,


    @Inject(OKTA_CONFIG) okta: { oktaAuth: OktaAuth }
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    // added for okta Start
    authStateService.authState$
      .pipe(filter((state) => state.hasOwnProperty('accessToken')))
      .subscribe((state) => {
        if (!state) {
          okta.oktaAuth.signInWithRedirect({
            originalUri: window.location.href,
          });
        } else {
          const userInfo = okta.oktaAuth.getUser().then((res) => {
            console.log(res, 'res');
            this.FirstName = res.given_name;
            this.lastName = res.family_name;

            this.userInitials = this.getUserInitials(
              this.FirstName,
              this.lastName
            );
            console.log(this.FirstName, 'INheaderFirst');
            console.log(this.lastName, 'INHEADERLast');
            this.menuData = this.getMenusfromdb();
            console.log(this.menuData, 'After auth menu');
          });
        }
      });

    const currentDate = new Date();
    const currentHour = currentDate.getHours();

    switch (true) {
      case (currentHour < 12): {
        this.message = 'Good Morning';
        break;
      }
      case (currentHour >= 12 && currentHour <= 18): {
        this.message = 'Good Afternoon';
        break;
      }
      case (currentHour > 18): {
        this.message = 'Good Evening';
        break;
      }
    }
  }
  menuData: any = [];
  items: MenuItem[];
  public ngOnInit() {
    this.items = [
      {
        label: 'Home', styleClass: '', command: () => this.changeRoute('/')
      },
      {
        label: 'Standard Reports', styleClass: '', command: () => this.changeRoute('standard-reports/sfdr-bmr')
      },
      {
        label: 'Custom Reports', styleClass: '', command: () => this.changeRoute('custom-reports/custom-sfdr-bmr')
      },
    ];

    this.primengConfig.ripple = true;
    console.log(this.sucessflag, 'suucessflag');
  }
  private getMenusfromdb() {
    this.menuService.getObjectMenu().subscribe((res) => {
      this.menuData = res;
      console.log(this.menuData, 'menudata');
    });
  }
  private getUserInitials(firstName: string, lastName: string): string {
    return (
      (firstName ? firstName[0].toUpperCase() : '') +
      (lastName ? lastName[0].toUpperCase() : '')
    );
  }

  mobileQuery: MediaQueryList;

  fillerNav = Array.from({ length: 50 }, (_, i) => `Nav Item ${i + 1}`);

  private _mobileQueryListener: () => void;

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  tabChange(url: string, id: number, menuName: string) {
    console.log(id, 'GetID');
    console.log(menuName, 'GetID');

    this.router.navigate([url], { state: { id: id, name: menuName } });
    //  debugger;
    const val = this.msgComm.getcommanMsg(true);

    console.log(val, 'value');
  }

  onLogin(user: string) {
    this.userName = user;
    console.log(this.userName, 'headerProject');
  }
  studsstream = new Observable(
    JSON.parse(localStorage.getItem('studs_stream'))
  );
  debugger;
  if(studsstream = true) {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: 'SFDRBMR-Sovereign report downloaded sucessfully',
    });
  }
  public changeRoute(newUrl: string): void {
    this.router.navigateByUrl(newUrl);
  }
}
