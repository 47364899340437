<footer class="footer fixed-bottom mt-auto py-3 d-none d-sm-block">
  <div class="container d-flex justify-content-between">
    <div>
      <span>&copy;{{currentYear}} Sustainalytics. All rights reserved.</span>
      <a href="https://www.sustainalytics.com/privacy-and-cookie-policy" rel="noopener noreferrer"
        target="_blank">Privacy Policy</a>
      <a href="https://www.sustainalytics.com/legal-disclaimers" rel="noopener noreferrer" target="_blank">Legal
        Disclaimers</a>
    </div>
    <a href="https://www.sustainalytics.com/about-us" rel="noopener noreferrer" target="_blank"
      style="text-decoration: underline;">About Sustainalytics</a>
  </div>
</footer>
