import { Component, OnInit } from '@angular/core';
import { RouterTabMenuModel } from '../comman/router-tab-menu/router-tab-menu.component';
import { MenuService } from '../../../shared/services/menu.service';

@Component({
  selector: 'app-custom-reports',
  templateUrl: './custom-reports.component.html',
  styleUrls: ['./custom-reports.component.scss']
})
export class CustomReportsComponent implements OnInit {

  public menuItems: RouterTabMenuModel[] = [];

constructor(public menuService: MenuService) { }

ngOnInit(): void {
  this.menuService.getObjectMenu().subscribe((res) => {
    res.forEach((r) => {
      if (r.id == 13) {
        r.children.forEach((child) => {
          this.menuItems.push({id: child.id, label: child.menuName, value: child.menuUrl.replace('/', '')});
        });          
      }
     });
  });
}
}