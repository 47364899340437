import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})


export class zipFoldeNameservice {
  today = new Date();


  public getZipfolderName(selectedProduct: string, selectedSource: string) {
    return selectedProduct + '_' + selectedSource + '_' + formatDate(this.today, 'yyyyMMddHHmm', 'en-us', '');
  }


  public getZipfolderNameCarbon(selectedProduct: string, selectedSource: string, selectedSubProduct: string) {
    return selectedProduct + '_' + selectedSource + '_' + selectedSubProduct + '_' + formatDate(this.today, 'yyyyMMddHHmm', 'en-us', '')
  }
}
