import {
  HttpClient,
  HttpEvent,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuditModel } from 'src/app/reports/models/audit.class';
import { ReportModel } from 'src/app/reports/models/report.class';

@Injectable()
export class PDBToolboxService {
  private url = '/v1/pdbtoolbox';

  constructor(private http: HttpClient) { }

  public getPulishedDateWithSubPrduct(Id: number, _env: string, Spdct: string) {
    return this.http.get<ReportModel[]>(
      this.url + `/${Id}/${_env}/${Spdct}/GetReports`
    );
  }
  public getAll2(Id: number, _env: string) {
    return this.http.get<ReportModel[]>(this.url + `/${Id}/${_env}/GetReports`);
  }
  // public getAll(Id: number) {
  //   return this.http.get<ReportModel[]>(this.url + `/${Id}/GetReports`);
  // }

  public getAuditInfo(auditProcedureName: string, id: number) {
    return this.http.get<AuditModel>(
      this.url + `/${auditProcedureName}/${id}/audit`
    );
  }

  public runReport({
    report,
    id,
  }: {
    report: string;
    id: number;
  }): Observable<HttpEvent<Blob>> {
    let queryParams = new HttpParams().append(report, id);

    return this.http.request(
      new HttpRequest(
        'GET',
        `${this.url}/${report}/${id}/run`,
        null,
        {
          reportProgress: true,
          responseType: 'blob',

        }

      )
    );
  }
}
