<div class="grid">
    <div class="col-12 pageHeader">
        <h2>Custom Reports</h2>        
    </div>
    <div class="col-12 layoutPad">
        <div class="grid">
            <div class="col-3">
                <app-router-tab-menu [menuItems]="menuItems" appTemplateDisplayMenu></app-router-tab-menu>    
            </div>
            <div class="col-9">
                <router-outlet></router-outlet>
            </div>
            
        </div>
    </div>
</div>