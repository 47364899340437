import { Component, OnInit } from '@angular/core';
import { RouterTabMenuModel } from '../comman/router-tab-menu/router-tab-menu.component';
import { MenuService } from '../../../shared/services/menu.service';

@Component({
  selector: 'app-standard-reports',
  templateUrl: './standard-reports.component.html',
  styleUrls: ['./standard-reports.component.scss']

})
export class StandardReportsComponent implements OnInit {

  public menuItems: RouterTabMenuModel[] = [];

  constructor(public menuService: MenuService) { }

  ngOnInit(): void {
    this.menuService.getObjectMenu().subscribe((res) => {
      res.forEach((r) => {
        if (r.id == 7) {
          r.children.forEach((child) => {
            if (child.id != 19) // temp remove Admin
              this.menuItems.push({ id: child.id, label: child.menuName, value: child.menuUrl.replace('/', '') });
          });
        }
      });
    });
  }
}

