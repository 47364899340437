import { formatDate } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Component, OnInit, Output } from '@angular/core';
import { ProgressStatusEnum } from 'src/app/shared/models/progress-status.model';
import { RunReport } from 'src/app/shared/models/runreport.model';
import { ProductService } from 'src/app/shared/services/ProductService';
import { ReportModel } from '../../models/report.class';
import { errorlog } from '../../../shared/errorlog';
import * as filesaver from 'file-saver';
import { MessageService } from 'primeng/api';
import { messagesConst } from 'src/app/shared/models/constants/messagesConst';
import { zipFoldeNameservice } from "../../../shared/services/zipFoldeName.service";


interface subProducts {
  name: string;
  code: string;
}
@Component({
  selector: 'app-custom-esg-im',
  templateUrl: './custom-esg-im.component.html',
  styleUrls: ['./custom-esg-im.component.scss'],
})
export class CustomEsgImComponent implements OnInit {
  date14: Date;
  selectedSource: string;
  runReportObj = new RunReport();
  @Output() selectedPrdct: string;
  @Output() menuIds: string;
  sources: any[] = [{ name: 'Test', key: 'Test' }, { name: 'Live', key: 'Live' }];

  public reports: ReportModel[] = [];
  public selectedReport: ReportModel = new ReportModel();

  AllProduct: subProducts[];
  selectedProduct: string;
  public disabled: boolean;
  public fileName: string;
  public fileInDownload: string;
  public percentage: number;
  public showProgress: boolean;
  public showDownloadError: boolean;
  public showMessage: boolean;
  public showMessage1: boolean;
  public showMessage2: boolean;

  public loading: boolean;
  public menuId: any;

  public menuName: string;
  public tabselected: string;
  public uploadstatus: string;


  activeState: boolean[] = [true, false, false];
  targetedFiled: any;
  targetedUniverse: any;
  date1: string;
  dateTime = new Date();
  SpinnerPNG: boolean;
  erroeMsg: string;

  constructor(
    private pdbService: ProductService,
    public statusLoggin: errorlog,
    private messageService: MessageService,
    public zipFoldeName: zipFoldeNameservice


  ) {
    this.menuId = 15;
    this.menuName = history.state.name;
  }
  ngOnInit(): void {
    // this.menuIds = this.menuName;
    this.menuIds = 'ESG Impact Metrics';
    this.AllProduct = [
      { name: 'Carbon Emissions', code: 'CE' },
      { name: 'Carbon Focus', code: 'CF' },
      { name: 'Fossil Fuels', code: 'CFF' },
      { name: 'Solutions', code: 'CS' },
      { name: 'Risk Ratings', code: 'RS' },
    ];
  }
  public run() {
    if (this.tabselected == null || this.tabselected == 'Universe') {
      this.showMessage1 = false;
      this.tabselected = 'Universe';
    } else {
      if (
        this.uploadstatus == 'Fail' ||
        this.uploadstatus == null ||
        (this.tabselected == 'Upload' && this.uploadstatus == 'inUniverse')
      ) {
        this.showMessage1 = true;
        return;
      } else {
        this.showMessage1 = false;
        this.tabselected = 'Upload';
      }
    }

    if (this.date14 == null || this.date14 == undefined) {
      this.date14 = new Date();
    }

    if (!this.selectedSource) {
      this.messageService.add({
        severity: 'warn',
        summary: 'warning',
        detail: messagesConst.failMsgSource,
      });
      return;
    }
    if (!this.targetedFiled) {

      this.messageService.add({
        severity: 'warn',
        summary: 'warning',
        detail: messagesConst.failMsgtargetedFiled,
      });
      return;
    }

    if (
      (!this.targetedUniverse) &&
      (!this.uploadstatus || this.uploadstatus == 'Fail')
    ) {

      this.messageService.add({
        severity: 'warn',
        summary: 'warning',
        detail: messagesConst.failMsgtargetedUniverse,
      });
      return;
    }
    this.selectedProduct = 'ECG-IM';
    this.date1 = formatDate(this.date14, 'yyyy-MM-dd', 'en_US');
    console.log(this.date1, 'selected Date');
    this.runReportObj.id = this.menuId;
    this.runReportObj.date = this.date1;
    this.runReportObj.source = this.selectedSource;
    this.runReportObj.subProduct = this.selectedProduct;
    this.runReportObj.targetedField = this.targetedFiled;
    this.runReportObj.universe = this.targetedUniverse;
    this.runReportObj.selectedcomponyType = this.tabselected;

    this.statusLoggin.downloadStatus({ status: ProgressStatusEnum.START });
    this.showMessage = this.statusLoggin.downloadStatus({
      status: ProgressStatusEnum.START,
    });

    this.SpinnerPNG = true;
    this.showMessage = true;
    this.pdbService.runNewReport(this.runReportObj).subscribe(
      (res) => {
        switch (res.type) {
          case HttpEventType.DownloadProgress:

            this.statusLoggin.downloadStatus({
              status: ProgressStatusEnum.IN_PROGRESS,
              percentage: Math.round((res.loaded / res.total) * 100),
            });
            this.showProgress = this.statusLoggin.downloadStatus({
              status: ProgressStatusEnum.IN_PROGRESS,
              percentage: Math.round((res.loaded / res.total) * 100),
            });

            break;

          case HttpEventType.Response:
            this.statusLoggin.downloadStatus({
              status: ProgressStatusEnum.COMPLETE,
            });
            const downloadedFile = new Blob([res.body], {
              type: res.body.type,
            });
            filesaver.saveAs(downloadedFile, this.zipFoldeName.getZipfolderName('Impact metrics 1', this.selectedSource));

            break;
        }
        this.SpinnerPNG = false;
        this.showMessage = this.statusLoggin.downloadStatus({
          status: ProgressStatusEnum.COMPLETE,
        });
        this.showProgress = this.statusLoggin.downloadStatus({
          status: ProgressStatusEnum.COMPLETE,
        });
      },
      (error) => {
        this.SpinnerPNG = false;
        this.showMessage = false;
        this.showProgress = false;
        this.statusLoggin.downloadStatus({ status: ProgressStatusEnum.ERROR });
      }
    );

    this.SpinnerPNG = false;
    this.statusLoggin.downloadStatus({ status: ProgressStatusEnum.COMPLETE });

  }

  changeEnvirement(e) {
    // is called from inside component. for this specific component it doesn't do anything yet.
  }

  nullSource() {
    this.selectedSource = null;
  }
  public getTagetedfile(targetedFileFromChild: any): void {
    this.targetedFiled = targetedFileFromChild;
  }
  public getTagetedUniverse(targetdeUnivereseChild: []) {
    this.targetedUniverse = targetdeUnivereseChild;
  }

  public getUploadStatus(status: string) {
    this.uploadstatus = status;
  }
  handleChange(event) {
    var index = event.index;

    if (index == 0) {
      this.tabselected = 'Universe';
      this.uploadstatus = 'inUniverse';
    } else {
      this.tabselected = 'Upload';
    }
  }


}
