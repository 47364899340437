<ng-container>
    <p-fieldset legend="Data Fields" [toggleable]="true" id="UniverseParameters" class="fieldset-spacing">
       
<p-pickList [source]="sourceProducts" [target]="targetProducts" sourceHeader="Available Fields" targetHeader="Selected Fields" dragdrop="true"
    [responsive]="true" [sourceStyle]="{'height':'300px'}" [targetStyle]="{'height':'300px'}" filterBy="colomName"  (onMoveAllToTarget)="onElementSelect()" (onMoveToTarget)="onElementSelect()"
    sourceFilterPlaceholder="Search by name" targetFilterPlaceholder="Search by name">
    <ng-template let-sourceProducts pTemplate="item">
        <div class="product-item">
            
            <div class="product-list-action">
                <i class="pi pi-file product-category-icon"></i>
                <span [class]="'product-badge status-' + sourceProducts.colomName.toLowerCase()">{{sourceProducts.colomName}}</span>
            </div>
        </div>
    </ng-template>
</p-pickList>
    </p-fieldset>
</ng-container>
