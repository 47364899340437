import { AuditModel } from './audit.class';
import { ReportType } from './enums/report-type.enum';

export class ReportModel {
  public id: string;
  public storedProcedureName: string;
  public auditProcedureName: string;
  public type: ReportType;
  public requiredRole: string;
  public auditInfo: AuditModel;
}
