<div [class.horizontalParrent]="mode == 'horizontal'">
    <ul [class.ul-horizontal]="mode == 'horizontal'" style="margin: 0; padding: 0;">
        <li *ngFor="let button of menuItems">
            <div (click)="changeTab(button)" [class.menuItem]="mode=='vertical'" [class.h-menuItem]="mode=='horizontal'"
                [class.selected]="current == button.value">
                <div class="grid">
                    <div [ngClass]="iconTemplateRef? 'col-10' : 'col-12'">
                        {{button.label}}
                    </div>
                    <div *ngIf="iconTemplateRef" class="p-col-2">
                        <ng-container *ngIf="iconTemplateRef">
                            <ng-container [ngTemplateOutlet]="iconTemplateRef"
                                [ngTemplateOutletContext]="{ $implicit: button, index: i}"></ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>
