import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PDBToolboxService } from '../shared/services/PDBToolbox.service';
import { ReportsComponent } from './components/reports/reports.component';
import { ReportsRoutes } from './reports.routes';
import { CarbonReportComponent } from './components/carbon-report/carbon-report.component';
import { EsgImComponent } from './components/esg-im/esg-im.component';
import { SfdrBmrComponent } from './components/sfdr-bmr/sfdr-bmr.component';
import { SharedModule } from '../shared/shared.module';
import { ButtonModule } from 'primeng/button';
import { PanelMenuModule } from 'primeng/panelmenu';
import { EuTaxonomyComponent } from './components/eu-taxonomy/eu-taxonomy.component';
import { SFDRBMRSovereignComponent } from './components/sfdrbmr-sovereign/sfdrbmr-sovereign.component';
import { FieldsetModule } from 'primeng/fieldset';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CalendarModule } from 'primeng/calendar';
import { AccordionModule } from 'primeng/accordion';
import { CustomCarbonComponent } from './components/custom-carbon/custom-carbon.component';
import { PickListModule } from 'primeng/picklist';
import { ProductService } from '../shared/services/ProductService';
import { DataFieldComponent } from './components/comman/data-field/data-field.component';
import { UploadCompanyIdComponent } from './components/comman/upload-company-id/upload-company-id.component';
import { UniverseFiledComponent } from './components/comman/universe-filed/universe-filed.component';
import { CustomEsgImComponent } from './components/custom-esg-im/custom-esg-im.component';
import { TabViewModule } from 'primeng/tabview';
import { CustomSfdrbmrSoverignComponent } from './components/custom-sfdrbmr-soverign/custom-sfdrbmr-soverign.component';
import { CustomSfdrBmrComponent } from './components/custom-sfdr-bmr/custom-sfdr-bmr.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ImpactMatricsComponent } from './components/impact-matrics/impact-matrics.component';
import { ShedulerPickerComponent } from './components/comman/sheduler-picker/sheduler-picker.component';
import { RippleModule } from 'primeng/ripple';
import { DialogModule } from 'primeng/dialog';
import { DialogComponent } from './components/comman/dialog/dialog.component';
import { ToastModule } from 'primeng/toast';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { StandardReportsComponent } from './components/standard-reports/standard-reports.component';
import { CustomReportsComponent } from './components/custom-reports/custom-reports.component';
import { RouterTabMenuComponent } from './components/comman/router-tab-menu/router-tab-menu.component';
import { MuniReportComponent } from './components/muni-report/muni-report.component';
import { CwrReportComponent } from './components/cwr-report/cwr-report.component';
import { PcrmReportComponent } from './components/pcrm-report/pcrm-report.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ReportsRoutes,
    CommonModule,
    ButtonModule,
    PanelMenuModule,
    FieldsetModule,
    InputSwitchModule,
    CalendarModule,
    AccordionModule,
    PickListModule,
    TabViewModule,
    ProgressSpinnerModule,
    RippleModule,
    DialogModule,
    ToastModule,
    RadioButtonModule,
    InputNumberModule,
    CheckboxModule,
    DropdownModule,
  ],
  providers: [PDBToolboxService, ProductService],
  declarations: [
    ReportsComponent,
    EsgImComponent,
    SfdrBmrComponent,
    CarbonReportComponent,
    EuTaxonomyComponent,
    SFDRBMRSovereignComponent,
    CustomCarbonComponent,
    DataFieldComponent,
    UploadCompanyIdComponent,
    UniverseFiledComponent,
    CustomEsgImComponent,
    CustomSfdrbmrSoverignComponent,
    CustomSfdrBmrComponent,
    ImpactMatricsComponent,
    DialogComponent,
    ShedulerPickerComponent,
    StandardReportsComponent,
    CustomReportsComponent,
    RouterTabMenuComponent,
    MuniReportComponent,
    CwrReportComponent,
    PcrmReportComponent,
  ],
})
export class ReportsModule {}
