import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { ToastrService } from 'ngx-toastr';
import { Notification } from '../models/notification';
import { PDBToolboxService } from './PDBToolbox.service';
import { ProductService } from './ProductService';

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  private hubConnection!: signalR.HubConnection;

  constructor(
    private toastr: ToastrService,
    public PDBToolboxService: PDBToolboxService,
    public ProductService: ProductService
  ) {}
  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl('http://localhost:5000/Notify', {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .build();
    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch((err) => console.log('Error while starting connection: ' + err));
  };

  public addProductListner = () => {
    console.log('SendMessage from Angular');
    this.hubConnection.on('SendMessage', (notification: Notification) => {
      this.showNotification(notification);
      this.PDBToolboxService.runReport;

      this.hubConnection.on('DisplayMessage', (message: string) => {
        // this.Displaymsg(notification);
      });
    });
  };

  public addProductListnerCustom = () => {
    console.log('SendMessage from Angular');
    this.hubConnection.on('SendMessage', (notification: Notification) => {
      this.showNotification(notification);
      this.ProductService.runNewReport;

      this.hubConnection.on('DisplayMessage', (message: string) => {
        // this.Displaymsg(notification);
      });
    });
  };

  showNotification(notification: Notification) {
    console.log('notification from API');
    console.log(notification.message, 'message');
    this.toastr.success(
      notification.message,
      notification.productID + ' ' + notification.productName
    );
  }
}
