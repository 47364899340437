import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RunReport } from '../models/runreport.model';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private url = '/v1/CustomReport';

  constructor(private http: HttpClient) {}

  getProductsSmall() {
    return this.http.get<any[]>(this.url);
  }

  public getFieldS(Spdct: string, meuId: string, env: string) {
    return this.http.get<any[]>(
      this.url + `/${Spdct}/${meuId}/${env}/GetDataFileds`
    );
  }

  getUniverse() {
    return this.http.get<any>(this.url + `/getUniverse`);
  }

  public runReport({
    id,
    sSubProduct,
    sSource,
    sDate,
    sTargetedFiled,
    sTUniverese,
  }: {
    id: number;
    sSubProduct: string;
    sSource: string;
    sDate: string;
    sTargetedFiled: any;
    sTUniverese: any;
  }): Observable<HttpEvent<Blob>> {
    console.log(id, sSubProduct, sDate, sTargetedFiled, 'IN-ServiceProduct');

    return this.http.request(
      new HttpRequest(
        'GET',
        `${this.url}/${id}/${sSubProduct}/${sDate}/${sSource}/${JSON.stringify(
          sTargetedFiled
        )}/${JSON.stringify(sTUniverese)}/run`,
        null,
        {
          reportProgress: true,
          responseType: 'blob',
        }
      )
    );
  }
  public runNewReport(data: RunReport): Observable<HttpEvent<Blob>> {
    return this.http.post<any>(this.url + '/run', data, {
      responseType: 'blob' as 'json',
      observe: 'response',
    });
  }
}
