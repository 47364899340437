import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CarbonReportComponent } from './components/carbon-report/carbon-report.component';
import { ReportsComponent } from './components/reports/reports.component';
import { EsgImComponent } from './components/esg-im/esg-im.component';
import { SfdrBmrComponent } from './components/sfdr-bmr/sfdr-bmr.component';
import { EuTaxonomyComponent } from './components/eu-taxonomy/eu-taxonomy.component';
import { SFDRBMRSovereignComponent } from './components/sfdrbmr-sovereign/sfdrbmr-sovereign.component';
import { CustomCarbonComponent } from './components/custom-carbon/custom-carbon.component';
import { CustomEsgImComponent } from './components/custom-esg-im/custom-esg-im.component';
import { CustomSfdrbmrSoverignComponent } from './components/custom-sfdrbmr-soverign/custom-sfdrbmr-soverign.component';
import { CustomSfdrBmrComponent } from './components/custom-sfdr-bmr/custom-sfdr-bmr.component';
import { ImpactMatricsComponent } from './components/impact-matrics/impact-matrics.component';
import { CustomReportsComponent } from './components/custom-reports/custom-reports.component';
import { StandardReportsComponent } from './components/standard-reports/standard-reports.component';
import { MuniReportComponent } from './components/muni-report/muni-report.component';
import { CwrReportComponent } from './components/cwr-report/cwr-report.component';
import { PcrmReportComponent } from './components/pcrm-report/pcrm-report.component';
const routes: Routes = [
  {
    path: '',
    component: ReportsComponent,
  },
  {
    path: 'reports',
    component: ReportsComponent,
  },

  {
    path: 'custom-reports',
    component: CustomReportsComponent,
    children: [
      {
        path: 'custom-carbon',
        component: CustomCarbonComponent,
      },

      {
        path: 'custom-esg-im',
        component: CustomEsgImComponent,
      },
      {
        path: 'custom-sfdrbmr-soverign',
        component: CustomSfdrbmrSoverignComponent,
      },
      {
        path: 'custom-sfdr-bmr',
        component: CustomSfdrBmrComponent,
      },
    ]
  },
  {
    path: 'standard-reports',
    component: StandardReportsComponent,
    children: [
      {
        path: 'eu-taxonomy',
        component: EuTaxonomyComponent,
      },
      {
        path: 'sfdr-bmr',
        component: SfdrBmrComponent,
      },
      {
        path: 'sfdrbmr-sovereign',
        component: SFDRBMRSovereignComponent,
      },
      {
        path: 'ecg-im',
        component: EsgImComponent,
      },
      {
        path: 'carbon-report',
        component: CarbonReportComponent,
      },

      {
        path: 'impact-matrics',
        component: ImpactMatricsComponent,
      },
      {
        path: 'muni-report',
        component: MuniReportComponent,
      },
      {
        path: 'cwr-report',
        component: CwrReportComponent,
      },
      {
        path: 'pcrm-report',
        component: PcrmReportComponent,
      },
    ]
  },

  {
    path: '',
    component: CarbonReportComponent,
  },
  {
    path: 'carbon-report',
    component: CarbonReportComponent,
  },
  {
    path: '',
    component: EsgImComponent,
  },
  {
    path: 'ecg-im',
    component: EsgImComponent,
  },
  {
    path: '',
    component: EsgImComponent,
  },
  {
    path: '',
    component: SfdrBmrComponent,
  },

  {
    path: 'sfdr-bmr',
    component: SfdrBmrComponent,
  },
  {
    path: 'eu-taxonomy',
    component: EuTaxonomyComponent,
  },
  {
    path: 'sfdrbmr-sovereign',
    component: SFDRBMRSovereignComponent,
  },
  {
    path: 'custom-carbon',
    component: CustomCarbonComponent,
  },

  {
    path: 'custom-esg-im',
    component: CustomEsgImComponent,
  },
  {
    path: 'custom-sfdrbmr-soverign',
    component: CustomSfdrbmrSoverignComponent,
  },
  {
    path: 'custom-sfdr-bmr',
    component: CustomSfdrBmrComponent,
  },
  {
    path: 'impact-matrics',
    component: ImpactMatricsComponent,
  },

  {
    path: 'muni-report',
    component: MuniReportComponent,
  },
];

export const ReportsRoutes: ModuleWithProviders<any> = RouterModule.forRoot(
  routes,
  // { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' }
  { scrollPositionRestoration: 'enabled' }

);

