import { Component, Inject } from '@angular/core';
import { OKTA_CONFIG, OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { filter } from 'rxjs/operators';
import { hasTokensInHash, OktaAuth } from '@okta/okta-auth-js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'PDBToolbox';
  userFullName: string;
  hubMessage?: string;

  constructor(
    authStateService: OktaAuthStateService,
    @Inject(OKTA_CONFIG) okta: { oktaAuth: OktaAuth }
  ) {
    if (hasTokensInHash(window.location.hash)) {
      setTimeout(() => {
        okta.oktaAuth.handleLoginRedirect();
      }, 100);
    }


    authStateService.authState$
      .pipe(filter((state) => state.hasOwnProperty("accessToken"))).subscribe((state) => {
        if (!state.isAuthenticated) {
          okta.oktaAuth.signInWithRedirect({ originalUri: window.location.href });
        }


      })
  }

  userName = '';

  onLogin(user: string) {
    this.userName = user;
    console.log(this.userName, 'headerLog');
  }
}
