import { Component, VERSION, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '../services/menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  name = 'Angular ' + VERSION.major;

  menuData: any;
  menuId: any;

  constructor(public menuService: MenuService, private route: Router) {}
  ngOnInit() {
    this.menuService.getObjectMenu().subscribe((res) => {
      this.menuData = res;
      console.log(this.menuData, 'menudata');
    });
  }

  onSelect(itemid) {
    this.menuId = itemid.id;
    console.log(this.menuId, 'MenuINMenu');
  }
  
  tabChange(url: string, id: number) {
    this.route.navigate([url], { state: { id: id } });
  }
}
