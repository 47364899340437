import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class uploadService {
  private url = '/v1/upload';

  constructor(private http: HttpClient) {}

  uploadImage(formData: any) {
    return this.http.post<any>(`${this.url}/upload`, formData);
  }
}
