import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class LocalhostInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (window.location.origin.includes('localhost')) {
      return next.handle(req.clone({ url: `http://localhost:5000${req.url}` }));
    }

    return next.handle(req);
  }
}
