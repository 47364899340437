import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  @Input() display: boolean;

  ngOnInit(): void {
    // can't be removed due to OnInit implementation
  }

  onclose() {
    // can't be removed due to onclose call from HTML
  }
}
