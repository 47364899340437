import { HttpEventType } from '@angular/common/http';
import { Component, OnInit, Output } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { errorlog } from '../../../shared/errorlog';
import { Envirements } from '../../../shared/interfaces/environment';
import { ProgressStatusEnum } from '../../../shared/models/progress-status.model';
import { PDBToolboxService } from '../../../shared/services/PDBToolbox.service';
import { ReportModel } from '../../models/report.class';
import { SignalrService } from 'src/app/shared/services/signalr.service';
import * as filesaver from 'file-saver';
import { formatDate } from '@angular/common';
import { zipFoldeNameservice } from "../../../shared/services/zipFoldeName.service";

@Component({
  selector: 'app-carbon-report',
  templateUrl: './carbon-report.component.html',
  styleUrls: ['./carbon-report.component.scss'],
  providers: [MessageService],
})
export class CarbonReportComponent implements OnInit {
  selectedSource: string;
  selectedPrdct: string;
  @Output() menuIds: string;

  sources: any[] = [{ name: 'Test', key: 'Test' }, { name: 'Live', key: 'Live' }];
  public reports: ReportModel[] = [];
  public selectedReport: ReportModel = new ReportModel();
  AllEnvirements: Envirements[];
  selectedEnvirements: string;
  public disabled: boolean;
  public fileName: string;
  public fileInDownload: string;
  public percentage: number;
  public showProgress: boolean;
  public showDownloadError: boolean;
  public showMessage: boolean;
  public loading: boolean;
  public menuId: any;
  public env: any;
  showSorceError: boolean;
  showproductError: boolean;
  today = new Date();

  constructor(
    private pdbService: PDBToolboxService,
    public statusLoggin: errorlog,
    private primengConfig: PrimeNGConfig,
    public singlarService: SignalrService,
    public zipFoldeName: zipFoldeNameservice
  ) {
    this.menuId = 3;
    this.singlarService.startConnection();
    this.singlarService.addProductListner();
  }

  ngOnInit() {

    this.AllEnvirements = [
      { name: 'Emissions', code: 'CE' },
      { name: 'Stranded Assets', code: 'SA' },
      { name: 'Fossil Fuels', code: 'CFF' },
      { name: 'Solutions', code: 'CS' },
      { name: 'Risk Ratings', code: 'RS' },

      // { name: 'Emissions', code: 1 },
      // { name: 'Stranded Assets', code: 2 },
      // { name: 'Fossil Fuels', code: 'CFF' },
      // { name: 'Solutions', code: 'CS' },
      // { name: 'Risk Ratings', code: 'RS' },

    ];


    this.menuIds = this.menuId;

    this.primengConfig.ripple = true;

    console.log(this.AllEnvirements, "ListDR")
  }

  public AuditInfo() {

    if (this.selectedEnvirements) {

      this.selectedPrdct = this.selectedEnvirements['code'];

    }
    else {

      this.showDownloadError = false;
      this.showSorceError = false;
      this.showproductError = true;
      return;
    }
    if (this.selectedPrdct) {
      this.selectedEnvirements;
      this.selectedSource;

      this.loading = false;
      this.pdbService
        .getPulishedDateWithSubPrduct(this.menuId, this.selectedSource, this.selectedPrdct)

        .subscribe((result) => {
          this.reports = result;
          this.selectedReport = this.reports[0];
          this.pdbService
            .getAuditInfo(this.selectedReport.auditProcedureName, this.menuId)
            .subscribe(
              (auditResult) => (this.selectedReport.auditInfo = auditResult)
            );
        });
    } else {
      this.showDownloadError = false;
      this.showSorceError = false;
      this.showproductError = true;
    }
  }

  public run() {
    if (this.selectedReport.storedProcedureName != undefined) {
      this.showSorceError = false;
      this.statusLoggin.downloadStatus({ status: ProgressStatusEnum.START });
      this.showMessage = this.statusLoggin.downloadStatus({
        status: ProgressStatusEnum.START,
      });
      this.pdbService
        .runReport({
          report: this.selectedReport.storedProcedureName,
          id: this.menuId,
        })
        .subscribe(
          (data) => {
            switch (data.type) {
              case HttpEventType.DownloadProgress:
                this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.IN_PROGRESS,
                  percentage: Math.round((data.loaded / data.total) * 100),
                });

                this.showProgress = this.statusLoggin.downloadProgress({
                  status: ProgressStatusEnum.IN_PROGRESS,
                  percentage: Math.round((data.loaded / data.total) * 100),
                });
                break;
              case HttpEventType.Response:
                this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.COMPLETE,
                });
                const downloadedFile = new Blob([data.body], {
                  type: data.body.type,
                });


                this.showMessage = this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.COMPLETE,
                });
                this.showProgress = this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.COMPLETE,
                });
                filesaver.saveAs(downloadedFile, this.zipFoldeName.getZipfolderNameCarbon('Carbon', this.selectedEnvirements['name'], this.selectedSource));

                break;
            }
          },
          (_error) => {
            this.statusLoggin.downloadStatus({
              status: ProgressStatusEnum.ERROR,
            });
          }
        );
    } else {
      this.showDownloadError = false;
      this.showproductError = false;

      this.showSorceError = true;
    }
  }
  downloadStatus(_arg0: { status: ProgressStatusEnum; percentage: number }) {
    throw new Error('Method not implemented.');
  }

  handleChange() {
    if (this.selectedReport.auditInfo === undefined) {
      this.loading = true;
      this.pdbService
        .getAuditInfo(this.selectedReport.auditProcedureName, this.menuId)
        .subscribe((auditResult) => {
          this.selectedReport.auditInfo = auditResult;
          this.loading = false;
        });
    }
  }
  changeEnvirement(_e) {
    this.AuditInfo();
  }
  nullSource() {
    this.selectedSource = null;
  }

  getZipFileName() {
    return 'Carbon' + '_' + this.selectedEnvirements['name'] + '_' + this.selectedSource + '_' + formatDate(this.today, 'yyyyMMddHHmm', 'en-us', '')
  }
}
