<ng-container>
    <p-fieldset legend="Universe" [toggleable]="true" id="UniverseParameters" class="fieldset-spacing">

        <p-pickList [source]="sourceProducts" [target]="targetProducts" sourceHeader="Available" targetHeader="Selected"
            dragdrop="true" [responsive]="true" [sourceStyle]="{'height':'300px'}" [targetStyle]="{'height':'300px'}"
            filterBy="colomName,id" (onMoveToTarget)="onElementSelect()" sourceFilterPlaceholder="Search by Name"
            targetFilterPlaceholder="Search by Name">
            <ng-template let-sourceProducts pTemplate="item">
                <div class="product-item">
                    <div class="product-list-action">
                        <h6 class="p-mb-2">{{sourceProducts.id}}</h6>
                        <span
                            [class]="'product-badge status-' + sourceProducts.colomName.toLowerCase()">{{sourceProducts.colomName}}</span>
                    </div>
                </div>
            </ng-template>
        </p-pickList>
    </p-fieldset>
</ng-container>