import { Component, Input, OnInit } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { scheduleInfo } from 'src/app/shared/models/scheduleInfo.model';
import { ScheduledDatesService } from 'src/app/shared/services/Sheduled-dates.service';

interface Accurance {
  name: string;
  code: string;
}
interface Iweekdays {
  name: string;
  code: string;
}
@Component({
  selector: 'app-sheduler-picker',
  templateUrl: './sheduler-picker.component.html',
  styleUrls: ['./sheduler-picker.component.scss'],
})
export class ShedulerPickerComponent implements OnInit {
  [x: string]: any;
  @Input() menuIds: string;

  frequency: string;
  selectedValue: string;
  selectedendDate: string;
  selectedRecur: string;
  selectedNodate: string;
  selectedmontlydaysNo: string;
  selectedmontlydays: string;
  public btnType: boolean = false;
  public shedulerId: string;

  public scheduleInfoModule: scheduleInfo[] = [];

  selectedCheckBox: string[] = [];
  date: Date;
  val: number;
  valMonthday: number;
  valMonth: number;
  valEndAfterRecc: number;

  selectedFrequency: any = null;

  categories: any[] = [
    { name: 'Daily', key: 'D' },
    { name: 'Weekly', key: 'W' },
    { name: 'Monthly', key: 'M' },
    { name: 'Yearly', key: 'R' },
  ];
  Accuryencies: Accurance[];
  Weekdaysone: Iweekdays[];

  selectedAccurance: Accurance;
  selectedWeekdays: Iweekdays;
  startDate: Date;
  endDate: Date;
  ScheduleinfoObj = new scheduleInfo();

  sheduleSwitchchecked: boolean;
  valDaily: number;
  valWeekly: number;
  showerror: boolean = false;

  constructor(
    private scheduledDatesService: ScheduledDatesService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig
  ) {
    this.Accuryencies = [
      { name: 'First', code: '1' },
      { name: 'Second', code: '2' },
      { name: 'Third', code: '3' },
      { name: 'Fourth', code: '4' },
      { name: 'Five', code: '5' },
    ];

    this.Weekdaysone = [
      { name: 'Monday', code: '1' },
      { name: 'Tuesday', code: '2' },
      { name: 'Wedsday', code: '3' },
      { name: 'Thursday', code: '4' },
      { name: 'Friday', code: '5' },
      { name: 'Saturday', code: '6' },
      { name: 'Sunday', code: '7' },
    ];
  }

  ngOnInit(): void {
    this.selectedFrequency = this.categories[1];
    this.getSheduleInfo(this.menuIds);
  }
  changeFrequency() {
    var radioSelect = this.selectedFrequency.key;
  }

  public getSheduleInfo(rMenuID) {

    this.scheduledDatesService
      .getSheduleInfo(rMenuID)

      .subscribe((result) => {
        this.scheduleInfoModule = result;
        if (this.scheduleInfoModule[0]) {
          this.shedulerId = this.scheduleInfoModule[0].sheduleId;
          if (this.scheduleInfoModule[0].report != null) {
            this.btnType = true;
            this.sheduleSwitchchecked = true;
            this.date = new Date(2023, 4, 4, 17, 23, 42, 11);
            this.date.setDate(13);
            this.date.setMonth(13);
            this.date.setFullYear(2023);
            this.date.setHours(this.scheduleInfoModule[0].hours);
            this.date.setMinutes(this.scheduleInfoModule[0].mins);

            this.startDate = new Date(
              this.scheduleInfoModule[0].recc_start_date
            );

            if (this.scheduleInfoModule[0].recc_end_date != null && this.scheduleInfoModule[0].recc_end_date.toString() != '0001-01-01T00:00:00') {
              console.log(this.scheduleInfoModule[0].recc_end_date, 'in end date');
              this.RadioselectRecurence = 'valEndDate';
              this.endDate = new Date(this.scheduleInfoModule[0].recc_end_date);
            }
            else if (this.scheduleInfoModule[0].recc_end_after != 0 && this.scheduleInfoModule[0].recc_end_after != null) {
              this.RadioselectRecurence = 'valrecurButton';
              this.valEndAfterRecc = this.scheduleInfoModule[0].recc_end_after;
            }
            else {
              this.RadioselectRecurence = 'valNoEndDate';
            }
          }

          // FOR DAILY
          if (
            this.scheduleInfoModule[0].daily_frequency != 0 ||
            this.scheduleInfoModule[0].daily_workday != null
          ) {
            if (this.scheduleInfoModule[0].daily_frequency != 0) {
              this.selectedFrequency = this.categories[0];

              this.selectedValue = 'Daily';
              this.valDaily = this.scheduleInfoModule[0].daily_frequency;
            } else {
              this.selectedValue = 'WeekDays';
            }
          }
          // FOR WEEKLY
          if (this.scheduleInfoModule[0].weekly_frequency != 0) {
            this.selectedFrequency = this.categories[1];
            this.valWeekly = this.scheduleInfoModule[0].weekly_frequency;
            var weekDyas = this.scheduleInfoModule[0].weekly_days.split(',');

            if (weekDyas.length > 0) {
              this.selectedCheckBox = weekDyas;
            }
          }
          // FOR Monthly
          if (this.scheduleInfoModule[0].monthly_NoOfmonth != 0) {
            this.selectedFrequency = this.categories[2];
            if (Number(this.scheduleInfoModule[0].monthly_NoOfdays) != 0) {
              this.RadioselectedValueMonthly = 'NoDayMonthR';
              this.valMonthday = Number(
                this.scheduleInfoModule[0].monthly_NoOfdays
              );
            } else {
              this.RadioselectedValueMonthly = 'DayMonthR';
              this.selectedAccurance =
                this.Accuryencies[
                this.scheduleInfoModule[0].monthly_nDayOfMonth
                ];
              this.selectedWeekdays =
                this.Weekdaysone[this.scheduleInfoModule[0].monthly_dayofMonth];
            }

            this.valMonth = this.scheduleInfoModule[0].monthly_NoOfmonth;
          }
        }
      });
  }

  public saveSchedule() {
    if (this.sheduleSwitchchecked == true) {
      this.ScheduleinfoObj.report = this.menuIds;

      //#region  time
      if (this.date != undefined) {
        this.ScheduleinfoObj.hours = this.date.getHours();
        this.ScheduleinfoObj.mins = this.date.getMinutes();
        //#endregion time
        //#region  selectedFrequncy
      } else {
        this.showerror = true;
        this.errorMsg = 'Please select hours and min';
        this.messageService.add({
          severity: 'error',
          summary: 'error',
          detail: 'Please select hours and min',
        });
      }
      if (this.selectedFrequency.key != undefined) {
        if (this.selectedFrequency.key == 'D') {
          if (this.selectedValue == 'Daily') {
            this.ScheduleinfoObj.daily_frequency = this.valDaily;
          } else {
            this.ScheduleinfoObj.daily_frequency = 0;
            this.ScheduleinfoObj.daily_workday = true;
          }
        } else if (this.selectedFrequency.key == 'W') {
          this.ScheduleinfoObj.daily_frequency = 0;
          this.ScheduleinfoObj.monthly_frequency = 0;

          this.ScheduleinfoObj.weekly_frequency = this.valWeekly;
          this.ScheduleinfoObj.weekly_days = this.selectedCheckBox.toString();
        } else if (this.selectedFrequency.key == 'M') {
          this.ScheduleinfoObj.daily_frequency = 0;
          this.ScheduleinfoObj.weekly_frequency = 0;
          this.ScheduleinfoObj.monthly_frequency = 1;

          if (this.RadioselectedValueMonthly == 'NoDayMonthR') {
            this.ScheduleinfoObj.monthly_NoOfdays = this.valMonthday;
            this.ScheduleinfoObj.monthly_NoOfmonth = this.valMonth;
          } else if (this.RadioselectedValueMonthly == 'DayMonthR') {
            this.ScheduleinfoObj.monthly_nDayOfMonth =
              this.selectedAccurance.code;
            this.ScheduleinfoObj.monthly_dayofMonth =
              this.selectedWeekdays.code;
            this.ScheduleinfoObj.monthly_NoOfmonth = this.valMonth;
          }
        }
        //#endregion selectedFrequncy
        if (this.startDate != undefined) {
          this.ScheduleinfoObj.recc_start_date = new Date(Date.UTC(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate()))
        } else {
          this.showerror = true;
          this.errorMsg = 'Please select Start Date';
          this.messageService.add({
            severity: 'error',
            summary: 'error',
            detail: 'Please select Start Date',
          });
          return;
        }

        if (this.RadioselectRecurence == 'valEndDate') {
          if (this.endDate < this.startDate) {
            this.showerror = true;
            this.errorMsg = 'Please select end date greater than start date';
            this.messageService.add({
              severity: 'error',
              summary: 'error',
              detail: 'Please select end date greater than start date',
            });
            return;
          }
          if (this.endDate != undefined || this.endDate != null) {
            this.ScheduleinfoObj.recc_end_date = new Date(Date.UTC(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate()))
          }
          else {
            this.showerror = true;
            this.errorMsg = 'Please select End Date';
            this.messageService.add({
              severity: 'error',
              summary: 'error',
              detail: 'Please select End Date',
            });
            return;
          }
        } else if (this.RadioselectRecurence == 'valrecurButton') {
          if (this.valEndAfterRecc != undefined) {
            this.ScheduleinfoObj.recc_end_after = this.valEndAfterRecc;
          } else {
            this.showerror = true;
            this.errorMsg = 'Please select End after recuurance';
            this.messageService.add({
              severity: 'error',
              summary: 'error',
              detail: 'Please select Start Date',
            });
            return;
          }
        } else {
          this.ScheduleinfoObj.recc_end_after = 0;
          this.ScheduleinfoObj.recc_end_date = null;
        }

        if (this.btnType == false) {
          this.scheduledDatesService
            .saveSheduleInfo(this.ScheduleinfoObj)
            .subscribe(
              (schduleResult) => {
                console.log(schduleResult.state);
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Scheduler save sucessfully',
                });
              },
              (_error) => {
                this.messageService.add({
                  severity: 'error',
                  summary: 'failed',
                  detail: 'Scheduler save failed',
                });
              }
            );
        } else {
          this.ScheduleinfoObj.sheduleId = this.shedulerId;
          this.scheduledDatesService
            .UpdateSheduleInfo(this.ScheduleinfoObj)
            .subscribe(
              (schduleResult) => {
                console.log(schduleResult.state);
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Scheduler Updated sucessfully',
                });
              },
              (_error) => {
                this.messageService.add({
                  severity: 'error',
                  summary: 'failed',
                  detail: 'Scheduler update failed',
                });
              }
            );
        }
      }
    }
  }
}
