import { HttpEventType } from '@angular/common/http';
import { Component, OnInit, Output } from '@angular/core';
import { ProgressStatusEnum } from 'src/app/shared/models/progress-status.model';
import { PDBToolboxService } from 'src/app/shared/services/PDBToolbox.service';
import { ReportModel } from '../../models/report.class';
import { UntypedFormControl } from '@angular/forms';
import { errorlog } from '../../../shared/errorlog';
import { ScheduledDatesService } from 'src/app/shared/services/Sheduled-dates.service';
import * as filesaver from 'file-saver';
import { zipFoldeNameservice } from "../../../shared/services/zipFoldeName.service";


interface Envirements {
  name: string;
  code: string;
}

@Component({
  selector: 'app-eu-taxonomy',
  templateUrl: './eu-taxonomy.component.html',
  styleUrls: ['./eu-taxonomy.component.scss'],
})
export class EuTaxonomyComponent implements OnInit {
  @Output() menuIds: string;
  activeState: boolean[] = [true, false, false];
  cronForm: UntypedFormControl;
  showSorceError: boolean;
  showproductError: boolean;
  selectedPrdct: string;

  toggle(index: number) {
    this.activeState[index] = !this.activeState[index];
  }

  selectedSource: string;
  sources: any[] = [{ name: 'Test', key: 'Test' }, { name: 'Live', key: 'Live' }];

  public reports: ReportModel[] = [];
  public selectedReport: ReportModel = new ReportModel();

  AllEnvirements: Envirements[];
  selectedEnvirements: Envirements;
  public disabled: boolean;
  public fileName: string;
  public fileInDownload: string;
  public percentage: number;
  public showProgress: boolean;
  public showDownloadError: boolean;
  public showMessage: boolean;
  public loading: boolean;
  public menuId: any;
  public env: any;
  public minDate = new Date(); // today
  public ReportName: string;
  constructor(
    private pdbService: PDBToolboxService,
    public statusLoggin: errorlog,
    private awsReportService: ScheduledDatesService,
    public zipFoldeName: zipFoldeNameservice

  ) {
    this.menuId = 6;
  }
  ngOnInit(): void {
    this.menuIds = this.menuId;
    console.log(this.menuIds, 'EUTax');
    this.cronForm = new UntypedFormControl('0 0 1/1 * *');

    this.AllEnvirements = [
      { name: 'Company Objective Conclusions', code: 'COC' },
      { name: 'Company Objective Alignment Conclusions', code: 'COAC' },
      { name: 'Company Conclusions', code: 'CC' },
      { name: 'Activity Conclusions', code: 'AC' },
      { name: 'Activity Alignment Conclusions', code: 'ACC' }, // not added in db
      { name: 'Cca Activity Alignment Conclusions', code: 'CAAC' },
      { name: 'Cca Activity Conclusions', code: 'CAC' },
      { name: 'Cca Company Objective Alignment Conclusions', code: 'CCOAC' },
      { name: 'Cca Company Objective Conclusions', code: 'CCOC' },
      { name: 'Cro Company Objective Alignment Conclusions', code: 'RCOAC' },// not added in db
      { name: 'Cro Activity Conclusions', code: 'RAC' },
      { name: 'Cro Company Objective Conclusions', code: 'RCOC' }, // not added in db

    ];
  }

  public AuditInfo() {
    this.selectedEnvirements;
    this.selectedSource;
    console.log(this.selectedEnvirements);
    console.log(this.selectedSource);

    this.loading = false;
    console.log(this.menuId);
    console.log(this.menuId, 'menuId');


    if (this.selectedEnvirements) {

      this.selectedPrdct = this.selectedEnvirements['code'];

    }
    else {

      this.showDownloadError = false;
      this.showSorceError = false;
      this.showproductError = true;
      return;
    }
    this.pdbService
      .getPulishedDateWithSubPrduct(this.menuId, this.selectedSource, this.selectedPrdct)
      .subscribe((result) => {
        this.reports = result;
        console.log(this.reports);
        this.selectedReport = this.reports[0];
        this.pdbService
          .getAuditInfo(this.selectedReport.auditProcedureName, this.menuId)
          .subscribe(
            (auditResult) => (this.selectedReport.auditInfo = auditResult)
          );
      });
  }

  public run() {
    if (this.selectedReport.storedProcedureName != undefined) {
      this.showSorceError = false;
      this.statusLoggin.downloadStatus({ status: ProgressStatusEnum.START });
      this.showMessage = this.statusLoggin.downloadStatus({
        status: ProgressStatusEnum.START,
      });
      this.pdbService
        .runReport({
          report: this.selectedReport.storedProcedureName,
          id: this.menuId,
        })
        .subscribe(
          (data) => {
            switch (data.type) {
              case HttpEventType.DownloadProgress:
                this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.IN_PROGRESS,
                  percentage: Math.round((data.loaded / data.total) * 100),
                });
                break;
              case HttpEventType.Response:
                this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.COMPLETE,
                });
                const downloadedFile = new Blob([data.body], {
                  type: data.body.type,
                });

                this.showProgress = this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.COMPLETE,
                });
                this.showMessage = this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.COMPLETE,
                });

                filesaver.saveAs(downloadedFile, this.zipFoldeName.getZipfolderNameCarbon('Eu-Taxonomy', this.selectedEnvirements['name'], this.selectedSource))
                break;
            }
          },
          (error) => {
            this.showDownloadError = true;
            this.statusLoggin.downloadStatus({
              status: ProgressStatusEnum.ERROR
            }
            );
          }
        );
    } else {

      this.showDownloadError = false;
      this.showproductError = false;
      this.showSorceError = true;
    }
  }

  handleChange() {
    if (this.selectedReport.auditInfo === undefined) {
      this.loading = true;
      this.pdbService
        .getAuditInfo(this.selectedReport.auditProcedureName, this.menuId)
        .subscribe((auditResult) => {
          this.selectedReport.auditInfo = auditResult;
          this.loading = false;
        });
    }
  }
  changeEnvirement(e) {
    this.AuditInfo();
  }

  public DownloadAwsReport() {
    this.ReportName = 'Carbon.csv';
    console.log(this.ReportName, 'ReportName');
    this.statusLoggin.downloadStatus({ status: ProgressStatusEnum.START });
    this.awsReportService
      .DownloadReportAWSS3({ report: this.ReportName })
      .subscribe(
        (data) => {
          switch (data.type) {
            case HttpEventType.DownloadProgress:
              this.statusLoggin.downloadStatus({
                status: ProgressStatusEnum.IN_PROGRESS,
                percentage: Math.round((data.loaded / data.total) * 100),
              });
              break;
            case HttpEventType.Response:
              this.statusLoggin.downloadStatus({
                status: ProgressStatusEnum.COMPLETE,
              });
              const downloadedFile = new Blob([data.body], {
                type: data.body.type,
              });
              const a = document.createElement('a');
              a.setAttribute('style', 'display:none;');
              document.body.appendChild(a);
              a.download = this.ReportName + '.csv';
              a.href = URL.createObjectURL(downloadedFile);
              a.target = '_blank';
              a.rel = 'noopener noreferrer';

              a.click();
              document.body.removeChild(a);
              break;
          }
        },
        (error) => {
          this.statusLoggin.downloadStatus({
            status: ProgressStatusEnum.ERROR,
          });
        }
      );
  }
  nullSource() {
    this.selectedSource = null;
    this.showproductError = false;
  }

}
