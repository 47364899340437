import { HttpEventType } from '@angular/common/http';
import { Component, OnInit, Output } from '@angular/core';
import { ProgressStatusEnum } from 'src/app/shared/models/progress-status.model';
import { PDBToolboxService } from 'src/app/shared/services/PDBToolbox.service';
import { ReportModel } from '../../models/report.class';
import { ActivatedRoute } from '@angular/router';
import { errorlog } from '../../../shared/errorlog';
import * as filesaver from 'file-saver';
import { zipFoldeNameservice } from "../../../shared/services/zipFoldeName.service";


interface Envirements {
  name: string;
  code: string;
}

@Component({
  selector: 'app-sfdr-bmr',
  templateUrl: './sfdr-bmr.component.html',
  styleUrls: ['./sfdr-bmr.component.scss'],
})
export class SfdrBmrComponent implements OnInit {
  selectedSource: string;
  selectedPrdct: string;
  sources: any[] = [{ name: 'Test', key: 'Test' }, { name: 'Live', key: 'Live' }];
  @Output() menuIds: string;

  public reports: ReportModel[] = [];
  public selectedReport: ReportModel = new ReportModel();

  AllEnvirements: Envirements[];
  selectedEnvirements: Envirements;
  public disabled: boolean;
  public fileName: string;
  public fileInDownload: string;
  public percentage: number;
  public showProgress: boolean;
  public showDownloadError: boolean;
  public showMessage: boolean;
  public loading: boolean;
  public menuId: any;
  public env: any;
  showSorceError: boolean;
  showproductError: boolean;
  today = new Date();

  constructor(
    private pdbService: PDBToolboxService,
    private actRoute: ActivatedRoute,
    public statusLoggin: errorlog,
    public zipFoldeName: zipFoldeNameservice

  ) {
    console.log(history.state, 'history sate');
    this.menuId = 4;
  }

  ngOnInit(): void {
    this.AllEnvirements = [
      { name: 'SFDR Corporate', code: 'SFDR' },
      { name: 'BMR Corporate', code: 'BMR' },

    ];
    this.menuIds = this.menuId;
  }

  public AuditInfo() {
    if (this.selectedEnvirements) {

      this.selectedPrdct = this.selectedEnvirements['code'];

    }
    else {

      this.showDownloadError = false;
      this.showSorceError = false;
      this.showproductError = true;
      return;
    }
    this.selectedSource;
    this.loading = false;
    this.pdbService.getPulishedDateWithSubPrduct(this.menuId, this.selectedSource, this.selectedPrdct).subscribe(
      (result) => {
        this.reports = result;
        this.selectedReport = this.reports[0];
        this.pdbService
          .getAuditInfo(this.selectedReport.auditProcedureName, this.menuId)
          .subscribe(
            (auditResult) => (this.selectedReport.auditInfo = auditResult)
          );
      },
      (error) => {
        this.showDownloadError = true;
        this.statusLoggin.downloadStatus({ status: ProgressStatusEnum.ERROR });
      }
    );
  }

  public run() {
    if (this.selectedReport.storedProcedureName != undefined) {
      this.showSorceError = false;
      this.statusLoggin.downloadStatus({ status: ProgressStatusEnum.START });
      this.showMessage = this.statusLoggin.downloadStatus({
        status: ProgressStatusEnum.START,
      });
      this.pdbService
        .runReport({
          report: this.selectedReport.storedProcedureName,
          id: this.menuId,
        })
        .subscribe(
          (data) => {
            switch (data.type) {
              case HttpEventType.DownloadProgress:
                this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.IN_PROGRESS,
                  percentage: Math.round((data.loaded / data.total) * 100),
                });
                this.showProgress = this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.IN_PROGRESS,
                  percentage: Math.round((data.loaded / data.total) * 100),
                });
                console.log('in-process');
                break;
              case HttpEventType.Response:
                this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.COMPLETE,
                });
                const downloadedFile = new Blob([data.body], {
                  type: data.body.type,
                });

                this.showMessage = this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.COMPLETE,
                });
                this.showProgress = this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.COMPLETE,
                });

                filesaver.saveAs(downloadedFile, this.zipFoldeName.getZipfolderName('SFDR-BMR Corporate', this.selectedSource));
            }
          },
          (error) => {
            console.log('Error');
            this.statusLoggin.downloadStatus({
              status: ProgressStatusEnum.ERROR,
            });
          }
        );
    } else {
      this.showDownloadError = false;
      this.showproductError = false;
      this.showSorceError = true;
    }
  }

  handleChange() {
    if (this.selectedReport.auditInfo === undefined) {
      this.loading = true;
      this.pdbService
        .getAuditInfo(this.selectedReport.auditProcedureName, this.menuId)
        .subscribe((auditResult) => {
          this.selectedReport.auditInfo = auditResult;
          this.loading = false;
        });
    }
  }
  changeEnvirement(e) {
    this.AuditInfo();
  }
  nullSource() {
    this.selectedSource = null;
  }

}
