export class messagesConst {

    public static sucessMsg: string = "";
    public static failMsgSource: string = 'Please select Source';



    public static failMsgtargetedFiled: string = 'Please select required fileds';
    public static failMsgProduct: string = 'Please select product';

    public static failMsgtargetedUniverse: string = 'Please select required Universe';

}