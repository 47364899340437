<ng-container>
  <p-fieldset legend="Company Id" [toggleable]="true" id="UniverseParameters" class="fieldset-spacing">

    <div class="container">
      <div class="main-content">
        <div class="main-section col-12 mt-5">


          <div id="QATools" class="card main shadow">
            <div class="card-body card-padding-bottom">
              <p *ngIf="showMessage">The output is being processed, please wait <img
                  src="assets/images/spinning-circles.svg" /> </p>
              <p *ngIf="showProgress">Download progress is <strong>{{percentage}}%</strong> <img
                  src="assets/images/spinning-circles.svg" /> </p>


              <div id="reportList">
                <div>
                  <a download="template.csv" target="_blank" rel="noopener noreferrer"
                    href="/assets/images/template.csv">
                    Click here to download template file
                  </a>
                </div>

                <div class="mt-4">
                  <input (change)="SelectFile($event)" id="file-input" type="file" accept="csv/*">
                  <button mat-flat-button color="primary" (click)="uploadFiles()">
                    Upload
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="audit" class="audit center"></div>
    </div>
    <div *ngIf="message.length" class="message">
      <ul *ngFor="let msg of message; let i = index">

        <li>{{ msg }}</li>
      </ul>
    </div>



  </p-fieldset>
</ng-container>