import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ProgressStatus,
  ProgressStatusEnum,
} from 'src/app/shared/models/progress-status.model';
import { uploadService } from 'src/app/shared/services/Upload.service';

@Component({
  selector: 'app-upload-company-id',
  templateUrl: './upload-company-id.component.html',
  styleUrls: ['./upload-company-id.component.scss'],
})
export class UploadCompanyIdComponent implements OnInit {
  @Output() uploadStatus = new EventEmitter<string>();

  selectedFiles?: FileList;
  selectedFileNames: string[] = [];
  progressInfos: any[] = [];
  message: string[] = [];
  previews: string[] = [];
  imageInfos?: Observable<any>;
  showProgress: boolean;
  iscsv: boolean;
  percentage: any;
  showMessage: boolean;
  showDownloadError: boolean;

  constructor(private uploadService: uploadService) {}

  ngOnInit(): void {
    this.uploadStatus.emit('Fail');
  }

  selectFiles(event: any): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFileNames = [];
    this.selectedFiles = event.target.files;

    this.previews = [];
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          console.log(e.target.result);
          this.previews.push(e.target.result);
        };

        reader.readAsDataURL(this.selectedFiles[i]);

        this.selectedFileNames.push(this.selectedFiles[i].name);
      }
    }
  }
  files: any;
  SelectFile(event: any): void {
    this.files = event.target.files[0];
    console.log(this.files, 'FileName');
    if (!this.files) {
      const msg = 'Please upload file first!';
      this.message.push(msg);
      this.uploadStatus.emit('Fail');
      this.message = [];

      return;
    }
    const string = this.files.type;
    const substring = 'csv';
    this.iscsv = string.includes(substring);
    if (this.iscsv == false) {
      const msg = 'Please upload csv file format from !';
      this.message.push(msg);
      this.uploadStatus.emit('Fail');
      return;
    }

    console.log(string.includes(substring));
  }

  uploadFiles(): void {
    if (!this.files) {
      this.message = [];

      const msg = 'Please upload file first!';
      this.message.push(msg);
      this.uploadStatus.emit('Fail');
      return;
    }
    if (this.iscsv == false) {
      this.uploadStatus.emit('Fail');

      return;
    }
    this.downloadStatus({ status: ProgressStatusEnum.START });
    const formData: FormData = new FormData();
    formData.append('file', this.files);
    this.uploadService.uploadImage(formData).subscribe(
      (res) => {
        if (res.success) {
          this.message = [];

          const msg = res.success;
          this.message.push(msg);
          console.log(this.message);
          this.downloadStatus({ status: ProgressStatusEnum.COMPLETE });
          this.uploadStatus.emit('Success');
          this.files.value = null;
        } else {
          this.message = [];

          this.downloadStatus({ status: ProgressStatusEnum.COMPLETE });
          const msg =
            'File failed to upload,Please check the file and try again';
          this.message.push(msg);
          console.log(this.message);
          this.uploadStatus.emit('Fail');
        }
      },
      (err) => {
        this.message = [];

        this.downloadStatus({ status: ProgressStatusEnum.ERROR });
        const msg = 'File failed to upload,Please check the file and try again';
        this.message.push(msg);
        console.log(this.message);
        this.uploadStatus.emit('Fail');
      }
    );

    this.downloadStatus({ status: ProgressStatusEnum.ERROR });
  }

  public downloadStatus(event: ProgressStatus) {
    switch (event.status) {
      case ProgressStatusEnum.START:
        this.showDownloadError = false;
        this.showMessage = true;
        console.log('START');
        break;
      case ProgressStatusEnum.IN_PROGRESS:
        this.showProgress = true;
        this.showMessage = false;
        this.percentage = event.percentage;
        console.log('IN_PROGRESS');
        break;
      case ProgressStatusEnum.COMPLETE:
        this.showProgress = false;
        this.showMessage = false;
        console.log('COMPLETE');
        break;
      case ProgressStatusEnum.ERROR:
        this.showProgress = false;
        this.showMessage = false;
        this.showDownloadError = true;
        console.log('ERROR');
        break;
    }
  }
}
