import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProgressStatusEnum } from 'src/app/shared/models/progress-status.model';
import { PDBToolboxService } from 'src/app/shared/services/PDBToolbox.service';
import { ReportModel } from '../../models/report.class';
import { errorlog } from '../../../shared/errorlog';
import { Message, MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import * as filesaver from 'file-saver';
import { CommonmsgService } from 'src/app/shared/services/commonmsg.service';
import { SignalrService } from 'src/app/shared/services/signalr.service';
import { zipFoldeNameservice } from "../../../shared/services/zipFoldeName.service";


interface Envirements {
  name: string;
  code: string;
}
@Component({
  selector: 'app-sfdrbmr-sovereign',
  templateUrl: './sfdrbmr-sovereign.component.html',
  styleUrls: ['./sfdrbmr-sovereign.component.scss'],
  providers: [MessageService],
})
export class SFDRBMRSovereignComponent implements OnInit {
  msgs1: Message[];
  msgs: Message[];
  @Output() menuIds: string;

  selectedSource: string;
  sources: any[] = [{ name: 'Test', key: 'Test' }, { name: 'Live', key: 'Live' }];

  @Output() public sucessflag = new EventEmitter<boolean>();

  public reports: ReportModel[] = [];
  public selectedReport: ReportModel = new ReportModel();

  AllEnvirements: Envirements[];

  selectedEnvirements: Envirements;
  public disabled: boolean;
  public fileName: string;
  public fileInDownload: string;
  public percentage: number;
  public showProgress: boolean;
  public showDownloadError: boolean;
  public showMessage: boolean;
  public loading: boolean;
  public menuId: any;
  public env: any;
  showproductError: boolean;
  showSorceError: boolean;
  today = new Date();

  constructor(
    private pdbService: PDBToolboxService,
    public statusLoggin: errorlog,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    public msgComm: CommonmsgService,
    public singlarService: SignalrService,
    public zipFoldeName: zipFoldeNameservice

  ) {
    this.menuId = 11;
    this.singlarService.startConnection();
    this.singlarService.addProductListner();
  }

  ngOnInit(): void {
    this.AllEnvirements = [
      { name: 'Carbon Emissions', code: 'CE' },
      { name: 'Carbon Focus', code: 'CF' },
      { name: 'Fossil Fuels', code: 'CFF' },
      { name: 'Solutions', code: 'CS' },
      { name: 'Risk Ratings', code: 'CS' },
    ];
    this.primengConfig.ripple = true;
    this.menuIds = this.menuId;
  }
  displayBasic: boolean;
  public AuditInfo() {
    this.selectedEnvirements;
    this.selectedSource;

    this.loading = false;
    this.pdbService
      .getAll2(this.menuId, this.selectedSource)

      .subscribe((result) => {
        this.reports = result;
        this.selectedReport = this.reports[0];
        this.pdbService
          .getAuditInfo(this.selectedReport.auditProcedureName, this.menuId)
          .subscribe(
            (auditResult) => (this.selectedReport.auditInfo = auditResult)
          );
      });
  }

  public run() {
    if (this.selectedReport.storedProcedureName != undefined) {
      this.showSorceError = false;
      this.statusLoggin.downloadStatus({ status: ProgressStatusEnum.START });
      this.showMessage = this.statusLoggin.downloadStatus({
        status: ProgressStatusEnum.START,
      });
      this.pdbService
        .runReport({
          report: this.selectedReport.storedProcedureName,
          id: this.menuId,
        })
        .subscribe(
          (data) => {
            switch (data.type) {
              case HttpEventType.DownloadProgress:
                this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.IN_PROGRESS,
                  percentage: Math.round((data.loaded / data.total) * 100),
                });
                this.showProgress = this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.IN_PROGRESS,
                  percentage: Math.round((data.loaded / data.total) * 100),
                });

                break;
              case HttpEventType.Response:
                this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.COMPLETE,
                });
                var downloadedFile = new Blob([data.body], {
                  type: data.body.type,
                });

                this.showProgress = this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.COMPLETE,
                });
                this.showMessage = this.statusLoggin.downloadStatus({
                  status: ProgressStatusEnum.COMPLETE,
                });

                filesaver.saveAs(downloadedFile, this.zipFoldeName.getZipfolderName('SFDR-BMR Sovereign', this.selectedSource));
                break;
            }
          },
          (error) => {
            this.hide();
            this.statusLoggin.downloadStatus({
              status: ProgressStatusEnum.ERROR,
            });
          }
        );
    } else {
      this.showDownloadError = false;
      this.showproductError = false;
      this.showSorceError = true;
    }
  }
  show() {

    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Message Content',
    });

    this.msgs1 = [
      { severity: 'success', summary: 'Success', detail: 'Message Content' },
    ];
  }
  hide() {
    this.msgs = [];
  }

  handleChange() {
    if (this.selectedReport.auditInfo === undefined) {
      this.loading = true;
      this.pdbService
        .getAuditInfo(this.selectedReport.auditProcedureName, this.menuId)
        .subscribe((auditResult) => {
          this.selectedReport.auditInfo = auditResult;
          this.loading = false;
        });
    }
  }
  changeEnvirement(e) {
    this.AuditInfo();
  }

}
