import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Product } from 'src/app/shared/interfaces/product';
import { ProductService } from 'src/app/shared/services/ProductService';

@Component({
  selector: 'app-data-field',
  templateUrl: './data-field.component.html',
  styleUrls: ['./data-field.component.scss'],
})
export class DataFieldComponent implements OnInit {
  @Input() selectedPrdct: string;
  @Input() menuIds: string;
  @Input() envirement: string;
  @Output() targetedFiled = new EventEmitter<any>();
  sourceProducts: Product[];
  targetProducts: Product[];

  constructor(
    private pProductService: ProductService,
    private primengConfig: PrimeNGConfig
  ) { }

  ngOnInit() {
    // can't be removed due to OnInit implementation
  }

  ngOnChanges() {
    if (this.selectedPrdct) {
      this.selectedPrdct = this.selectedPrdct['code'];
    }
    console.log(this.selectedPrdct, 'childChild');
    console.log(this.menuIds, 'MenuCaptured');
    console.log(this.envirement, 'Selectdenvirement');
    if (
      (this.menuIds != undefined || this.selectedPrdct != undefined) &&
      this.envirement != undefined
    ) {
      this.pProductService
        .getFieldS(this.selectedPrdct, this.menuIds, this.envirement)
        .subscribe((res) => {
          this.sourceProducts = res;
          this.targetProducts = [];
          this.primengConfig.ripple = true;
        });
    }
  }

  onElementSelect(): void {
    console.log(this.targetedFiled, 'TargetEmiter');
    console.log(this.targetProducts);

    this.targetedFiled.emit(this.targetProducts);
  }
}
