<div class="sidebar">

  <ul class="nav">
    <li (click)="onSelect(item)" *ngFor="let item of menuData">
      <a (click)="tabChange(item.menuUrl,item.id)">{{ item.menuName }}</a>

      <ul *ngIf="item.children">
        <li *ngFor="let submenu of item.children">
          <a (click)="tabChange(item.menuUrl,item.id)">{{ submenu.menuName }}</a>
        </li>
      </ul>

    </li>
  </ul>


</div>