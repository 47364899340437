import { Component, OnInit } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  items: MenuItem[];
  menuData: any;
  menuCarbon: any;
  urlCarbon: any;
  menuSfdrBmr: any;
  urlSfdrBmr: any;
  menuEcgIm: any;
  urlEcgIm: any;
  urlEutax: any;
  menuidCarbon: any;
  menuidsfdr: any;
  menuidEcgIm: any;
  menuidEuTax: any;
  array = [];
  subarray = [];
  menu4: any;
  subMenu: any;
  subMenuEuTax: any;
  SubArray4: any;
  constructor(public menuService: MenuService, private route: Router) {}
  ngOnInit() {
    this.menuService.getObjectMenu().subscribe((res) => {
      this.array = res;
      console.log(this.array, 'menudata');
      console.log(this.array[0].children, 'child');
      this.subarray = this.array[0].children;
      this.SubArray4 = this.array[3].children;

      this.array.forEach((elem) => {
        switch (elem.id) {
          case 3: {
            this.menuCarbon = elem.menuName;
            this.urlCarbon = elem.menuUrl;
            this.menuidCarbon = elem.id;
            this.subMenu = this.subarray[0].menuName;
            break;
          }
          case 4: {
            this.menuSfdrBmr = elem.menuName;
            this.urlSfdrBmr = elem.menuUrl;
            this.menuidsfdr = elem.id;
            break;
          }
          case 5: {
            this.menuEcgIm = elem.menuName;
            this.urlEcgIm = elem.menuUrl;
            this.menuidEcgIm = elem.id;
            break;
          }
          default: {
            this.menu4 = elem.menuName;
            this.menuidEuTax = elem.id;
            this.urlEutax = elem.menuUrl;
            this.subMenuEuTax = this.SubArray4[0].menuName;
            break;
          }
        }

        this.items = [
          {
            label: this.menuCarbon,
            icon: 'pi pi-fw pi-file',
            items: [
              {
                label: this.subMenu,
                icon: 'pi pi-fw pi-align-justify',
                routerLink: this.urlCarbon,
                id: this.menuidCarbon,

                command: () =>
                  this.tabChange(this.urlCarbon, this.menuidCarbon),

                routerLinkActiveOptions: { exact: true },
                queryParamsHandling: 'preserve',
              },
              {
                label: 'Custom Report',
                icon: 'pi pi-fw pi-align-justify',
              },
            ],
          },
          {
            label: this.menuSfdrBmr,
            icon: 'pi pi-fw pi-file',
            items: [
              {
                label: this.subMenu,
                icon: 'pi pi-fw pi-align-justify',
                routerLink: this.urlSfdrBmr,
                id: this.menuidsfdr,
                command: () => this.tabChange(this.urlSfdrBmr, this.menuidsfdr),
              },
            ],
          },
          {
            label: this.menuEcgIm,
            icon: 'pi pi-fw pi-file',
            items: [
              {
                label: this.subMenu,
                icon: 'pi pi-fw pi-align-justify',
                routerLink: this.urlEcgIm,
                id: this.menuidEcgIm,
                command: () => this.tabChange(this.urlEcgIm, this.menuidEcgIm),
              },
            ],
          },
          {
            label: 'SFDR-Sovereign',
            icon: 'pi pi-fw pi-file',

            items: [
              {
                label: 'Standard Report',
                icon: 'pi pi-sw pi-align-justify',
                routerLink: '/sfdrbmr-sovereign',
              },
            ],
          },

          {
            label: this.menu4,
            icon: 'pi pi-fw pi-file',
            items: [
              {
                label: this.subMenuEuTax,
                icon: 'pi pi-fw pi-align-justify',
                routerLink: this.urlEutax,
                id: this.menuidEuTax,

                command: () => this.tabChange(this.urlEutax, this.menuidEuTax),
              },
            ],
          },
        ];
      });
    });
  }
  tabChange(url: string, id: number) {
    this.route.navigate([url], { state: { id: id } });
  }
}
