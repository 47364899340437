import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OKTA_CONFIG } from '@okta/okta-angular';
import { Observable } from 'rxjs/internal/Observable';
import { OktaAuth } from '@okta/okta-auth-js';

@Injectable()
export class pdbtoolInterceptor implements HttpInterceptor {
  constructor(@Inject(OKTA_CONFIG) private okta: { oktaAuth: OktaAuth }) {}
  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.okta.oktaAuth.getIdToken()}`,
      },
    });
    return next.handle(request);
  }
}
