export class scheduleInfo {
  sheduleId: string;
  report: string;
  users: string;
  hours: number;
  mins: number;
  daily_frequency: number;
  daily_workday: boolean;
  weekly_frequency: number;
  weekly_days: string;
  monthly_frequency: number;
  monthly_NoOfdays: number;
  monthly_NoOfmonth: number;
  monthly_nDayOfMonth: string;
  monthly_dayofMonth: string;
  last_updated: Date;
  deleted: boolean;
  recc_start_date: Date;
  recc_end_date: Date;
  recc_end_after: number;
}



export enum ProgressStatusEnum {
  START,
  COMPLETE,
  IN_PROGRESS,
  ERROR,
}
