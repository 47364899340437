import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Product } from 'src/app/shared/interfaces/product';
import { ProductService } from 'src/app/shared/services/ProductService';

@Component({
  selector: 'app-universe-filed',
  templateUrl: './universe-filed.component.html',
  styleUrls: ['./universe-filed.component.scss'],
})
export class UniverseFiledComponent implements OnInit {
  @Output() targetedUniverse = new EventEmitter<any>();
  sourceProducts: Product[];
  targetProducts: Product[];

  constructor(
    private UniverseService: ProductService,
    private primengConfig: PrimeNGConfig
  ) {}

  ngOnInit(): void {
    this.UniverseService.getUniverse().subscribe((res) => {
      this.sourceProducts = res;
      this.targetProducts = [];
      this.primengConfig.ripple = true;
      console.log(this.sourceProducts, 'Coloumn');
    });
  }
  onElementSelect(): void {
    this.targetedUniverse.emit(this.targetProducts);
    console.log(this.targetedUniverse, 'Target');
  }
}
